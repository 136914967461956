
    import {
      getAllLabelGens,
      createLabelGen,
      createAllLabelGens,
      updateLabelGen,
      deleteLabelGen
    } from "@/services/labelGen";

    const state = {
      labelGens: [],
      processed: false
    };

    const getters = {
      getLabelGens(state) {
        return state.labelGens;
      },
      labelGenProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchLabelGens({ commit }, params) {
          return new Promise((resolve, reject) => {
            getAllLabelGens(
              params
            )
              .then(response => {
                commit("SET_LABELGENS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createLabelGen({ commit }, labelGen) {
          return new Promise((resolve, reject) => {
            createLabelGen(labelGen)
              .then(response => {
                commit("CREATED_LABELGEN");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllLabelGens({ commit }, labelGens) {
          return new Promise((resolve, reject) => {
            createAllLabelGens(labelGens)
              .then(response => {
                commit("CREATED_LABELGENS");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateLabelGen({ commit }, labelGen) {
          return new Promise((resolve, reject) => {
            updateLabelGen(labelGen)
              .then(response => {
                commit("UPDATED_LABELGEN");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteLabelGen({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteLabelGen(id)
              .then(response => {
                commit("DELETED_LABELGEN");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_LABELGENS(state, data) {
        state.labelGens = data;
        state.processed = false;
      },
      CREATED_LABELGEN(state) {
        state.processed = true;
      },
      CREATED_LABELGENS(state) {
        state.processed = true;
      },
      UPDATED_LABELGEN(state) {
        state.processed = true;
      },
      DELETED_LABELGEN(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    