
  const labElement = {
    path: "/labElement",
    name: "labElement",
    component: () => import(/* webpackChunkName: "lab_element" */ "@/components/labElement/LabElementIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "lab_element" */ "@/components/labElement/LabElementView.vue"), name: "labElementView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "lab_element" */ "@/components/labElement/LabElementEdit.vue"), name: "labElementEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "lab_element" */ "@/components/labElement/LabElementCreate.vue"), name: "labElementCreate"}
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default labElement;
    