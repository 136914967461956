"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
const AUTH_TOKEN = localStorage.getItem('token')
if(AUTH_TOKEN){
  axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
}

// Default to english
axios.defaults.headers.common["language"] = localStorage.getItem("locale") ? localStorage.getItem("locale"):'En';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_BASE_URL || process.env.apiUrl || "",
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // allow credentials to be send, including cookies in the request header
  crossorigin: true,
  changeOrigin:true
};

const _axios = axios.create(config);


Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
