import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("locale") || "en", // set locale
  messages: {
    en: require("../lang/en.json"),
    zhHans: require("../lang/zhHans.json"),
    th: require("../lang/th.json"),
  },
});

console.log(
  `secondary color: ` + colors[`${process.env.VUE_APP_COLOR_SECONDARY}`].darken1
);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      light: {
        primary: colors[`${process.env.VUE_APP_COLOR_PRIMARY}`],
        secondary: colors[`${process.env.VUE_APP_COLOR_SECONDARY}`].darken1,
        accent: colors[`${process.env.VUE_APP_COLOR_PRIMARY}`].accent4,
        anchor: colors[`${process.env.VUE_APP_COLOR_PRIMARY}`],
        error: colors[`${process.env.VUE_APP_COLOR_ERROR}`].accent3,
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
});
