export default class InventoryBalanceService {
  constructor(date, balances) {
    this.date = date;
    this.balances = balances;
    this._summarize(this.balances);
  }

  _summarize(balances) {
    let summary = {};
    if (!balances || balances.length < 1) {
      return summary;
    }

    this.data = this._groupByProduct(balances);
    this.productIds = Object.keys(this.data);
  }

  _groupByProduct(inventories) {
    return inventories.reduce(function(res, item) {
      if (!res[item.productId]) {
        res[item.productId] = {
          product: item.product,
          count: 0,
          originalValue: 0,
          originalQuantity: 0,
          remainingValue: 0,
          unitCost: 0,
          quantity: 0,
          inventories: []
        };
        inventories.push(res[item.productId]);
      }

      res[item.productId].count++;
      res[item.productId].quantity += item.quantityRemaining;
      res[item.productId].inventories.push(item);

      return res;
    }, {});
  }

  _merge(balance1, balance2) {
    const result = Object.assign({}, balance1);

    if(balance2 && balance2.inventories.length > 0){
      result.count += balance2.count;
      result.originalValue += balance2.originalValue;
      result.originalQuantity += balance2.originalQuantity;
      result.remainingValue += balance2.remainingValue;
      result.unitCost = ( (result.unitCost * result.originalQuantity) + (balance2.unitCost * balance2.originalQuantity) ) / (result.originalQuantity + balance2.originalQuantity);
      result.quantity += balance2.quantity;
      result.inventories = [...result.inventories, ...balance2.inventories ];
    }

    return result;
  }

  getDate() {
    return this.date;
  }

  getProductIds() {
    return this.productIds ? this.productIds:[];
  }

  _getleafBalance(productId) {
    const balance = this.data[productId];
    if(balance == null){
      return {
        product: null,
        count: 0,
        originalValue: 0,
        originalQuantity: 0,
        remainingValue: 0,
        unitCost: 0,
        quantity: 0,
        inventories: []
      };
    }
    else{
      return balance;
    }
  }

  getBalanceByProduct(product) {
    if(!this.data || this.data.length < 1){
      return {};
    }
    const existingBalance = this.data[product.id];
    if(existingBalance != null ){
      return existingBalance;
    }

    let rollupBalance = {};
    if(product.hasChildren || (product.children && product.children.length > 0) ) {
      //Find all leaf nodes under this product node and roll-up balance
      let leafNodeIds = product.leafNodeIds;
      if(!leafNodeIds && (product.children && product.children.length > 0)){
        leafNodeIds = product.children.map(p => p.id);
      }

      rollupBalance = leafNodeIds.reduce( (rollup, pId) => { 
        if(rollup == null){
          rollup = this._getleafBalance(pId);
        }
        else{
          rollup = this._merge(rollup, this._getleafBalance(pId));
        }

        return rollup;
      }, null)
    }
    else{
      rollupBalance = this._getleafBalance(product.id);
    }

    this.data[product.id] = rollupBalance;

    rollupBalance.product = product.name;
    return rollupBalance;
  }

  getAllInventories(){
    let allInventories = [];
    const productIds = Object.keys(this.data);
    if(productIds){
      productIds.forEach(id => {
        allInventories = allInventories.concat(this.data[id].inventories);
      })
    }
    return allInventories;
  }

  getInventoryIds(productId){
    let inventoryIds = [];

    if(productId){
      const productData = this.data[productId];
      if(productData){
        inventoryIds = this.data[productId].inventories.map((i) => i.inventoryId);
      }
    }
    else{
      this.getProductIds().forEach((productId) => {
        const productData = this.data[productId];
        if(productData){
          const ids = productData.inventories.map((i) => i.inventoryId);
          inventoryIds = inventoryIds.concat(ids);
        }
    });
    }

    return inventoryIds;
  }

  getPackingInventoryIds(){
    let packItemInventoryIds = [];

    this.getProductIds().forEach((productId) => {
      const productData = this.data[productId];
      if(productData){
        const productPackedItemInventoryId  = productData.inventories.filter(i => i.packedItemInventoryId).map((i) => i.packedItemInventoryId);
        packItemInventoryIds = packItemInventoryIds.concat(productPackedItemInventoryId);
      }
    })

    return packItemInventoryIds;
  }

  applyCostAdjustments(costAdjustmentService){

    // const balanceDate = this.date;

    this.getProductIds().forEach( (productId) => {
      const productData = this.data[productId];
      productData.inventories.forEach( (item) => {

        const inventoryId = item.inventoryId;
        // let inventoryCostAdjustment = costAdjustmentService.getAdjustmentCostOnDate(inventoryId, balanceDate);
        let inventoryCostAdjustment = costAdjustmentService.getAdjustmentCostOnDate(inventoryId);
        
        item.costAdjustment = inventoryCostAdjustment ? inventoryCostAdjustment:0;
        const inventoryCost = item.originalCost + inventoryCostAdjustment;
        item.unitCost = inventoryCost/item.originalQuantity;
        item.originalValue = inventoryCost;
        item.remainingValue = item.quantityRemaining * item.unitCost;

    // if(product == 'Soft Lead -Sample' && !inventoryCost){
    //   debugger;
    //   console.log('error...')
    // }

        productData.originalValue += inventoryCost;
        productData.remainingValue += item.remainingValue;
        productData.originalQuantity += item.originalQuantity;
      })

      //calculate average unit cost for product
      productData.unitCost = productData.originalValue/productData.originalQuantity;

    })


    this.totalValue = this.getProductIds().reduce((sum, productId) => {
      const value = this.data[productId].remainingValue;
      // if(!value){
      //   console.error('remaining value error for product ' + product)
      // }
      return (sum += value ? value:0);
    }, 0);

    this.totalQuantity = this.getProductIds().reduce((sum, productId) => {
      const quantity = this.data[productId].quantity;
      return (sum += quantity ? quantity:0);
    }, 0);

    this.totalCount = this.getProductIds().reduce((sum, productId) => {
      return (sum += this.data[productId].count);
    }, 0);

  }

  destroy() {
    delete this.data;
    delete this.balances;
    delete this.productIds;
  }
}
