
  const accountClosing = {
    path: "/accountClosing",
    name: "accountClosing",
    component: () => import(/* webpackChunkName: "account_closing" */ "@/components/accountClosing/AccountClosingIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "account_closing" */ "@/components/accountClosing/AccountClosingView.vue"), name: "accountClosingView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "account_closing" */ "@/components/accountClosing/AccountClosingEdit.vue"), name: "accountClosingEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "account_closing" */ "@/components/accountClosing/AccountClosingCreate.vue"), name: "accountClosingCreate"}
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default accountClosing;
    