const state = {
	messageGroup: {
		// messageClass: 'danger',
		// message: 'Test'
		messageClass: '',
		message: '',
		timeoutEvent: null,
	},
	timeout: 5000
}

const mutations = {
	'ADD_MESSAGE' (state, {message, messageClass}) {
		const msgTimeoutSeconds = state.timeout ? state.timeout:5000; //default to 5 seconds
		state.messageGroup = {
			messageClass: messageClass || 'warning',
			message: message || 'Oop.. an unknown error occurred'
		}
		if (state.timeoutEvent) {
			clearTimeout(state.timeoutEvent);
		}
		state.timeoutEvent = setTimeout(function() {
			state.messageGroup = {
				messageClass: '',
				message: ''
			}
		}, msgTimeoutSeconds);
	},
	'CLEAR_MESSAGE' (state) {
		state.messageGroup = {
			messageClass: '',
			message: '',
		}
	},
	'SET_TIMEOUT' (state, {timeout}) {
		state.timeout = timeout
	}
}

const actions = {
	// Don't need action for now
	addMessage({commit}, msg) {
		console.log('showing message...')
		commit('ADD_MESSAGE', { message: msg, messageClass: 'warning'});
	},
	addInfoMessage({commit}, msg) {
		commit('ADD_MESSAGE', { message: msg, messageClass: 'info'});
	},
	addSuccessMessage({commit}, msg) {
		commit('ADD_MESSAGE', { message: msg, messageClass: 'success'});
	},
	addErrorMessage({commit}, msg) {
		commit('ADD_MESSAGE', { message: msg, messageClass: 'danger'});
	},
	clearMessage({commit}) {
		commit('CLEAR_MESSAGE');
	},
	setMsgTimeout({commit}, timeout) {
		commit('SET_TIMEOUT', timeout);
	}
}

const getters = {
	messages: (state) => {
		return state.messageGroup;
	}
}

export default {
	state,
	mutations,
	actions,
	getters
}
