
    import {
      getAllCompanyVehicles,
      createCompanyVehicle,
      createAllCompanyVehicles,
      updateCompanyVehicle,
      deleteCompanyVehicle
    } from "@/services/companyVehicle";

    const state = {
      companyVehicles: [],
      processed: false
    };

    const getters = {
      getCompanyVehicles(state) {
        return state.companyVehicles;
      },
      companyVehicleProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchCompanyVehicles({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllCompanyVehicles(
              params
            )
              .then(response => {
                commit("SET_COMPANYVEHICLES", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createCompanyVehicle({ commit }, companyVehicle) {
          return new Promise((resolve, reject) => {
            createCompanyVehicle(companyVehicle)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllCompanyVehicles({ commit }, companyVehicles) {
          return new Promise((resolve, reject) => {
            createAllCompanyVehicles(companyVehicles)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateCompanyVehicle({ commit }, companyVehicle) {
          return new Promise((resolve, reject) => {
            updateCompanyVehicle(companyVehicle)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteCompanyVehicle({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteCompanyVehicle(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_COMPANYVEHICLES(state, data) {
        state.companyVehicles = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    