
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

const state = {
  parameters: {
    startDate: new Date().addDays(-30),
    endDate: new Date(),
    eventTypes: ['inputs', 'outputs', 'purchases', 'sold', 'adjustments'],
    // eventTypes: [],
    processTypes: []
  },
  loading: false
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setProcessTypes(state, list) {
    state.parameters.processTypes = list;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  updateParameters({commit}, params) {
    commit('setParameters', params);
  },
  setProcessTypes({commit}, processTypes) {
    commit('setProcessTypes', processTypes);
  },
};

const getters = {
  parameters: state => {
    return state.parameters;
  },
  isLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
