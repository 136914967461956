

export default function (_axios, _store, _router) {
    // Intercept all ajax requests
    _axios.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            _store.dispatch('navigation/appLoading');
            // console.log(config)
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error).then(error => {
                _store.dispatch('navigation/stopAppLoading');

                _store.commit("ADD_MESSAGE", error);
            });
        }
    );

    // Intercept all ajax responses
    _axios.interceptors.response.use(
        function (response) {
            // Do something with response data
            _store.dispatch('navigation/stopAppLoading');

            return response;
        },
        function (err) {
            _store.dispatch('navigation/stopAppLoading');
            // console.log('Error response')
            // console.log(err.response)
            return new Promise((resolve, reject) => {
                if (!err.response) {
                    // network error
                    const message = 'Network error, please check your internet connection or try again later';
                    _store.dispatch('messages/addErrorMessage', message)
                    reject(err);
                }
                else if (err.response &&
                    err.response.status === 500
                ) {
                // console.log('Error code 500')
                    const message = err.response.data.message || 'Server error, please try again later';
                    _store.dispatch('messages/addErrorMessage', message)
                    reject(err.response);
                }
                else if (err.response &&
                    err.response.status === 401 
                ) {
                    _store.commit("logout");
                    const path = '/login'
                    if (_router.currentRoute.path !== path) _router.push(path)

                    reject(err.response);
                }
                else if (err.response &&
                   err.response.status === 403
                ) {
                // console.log('no permission')
                    const message = 'no permission';
                    _store.dispatch('messages/addErrorMessage', message)
                    _store.commit("logout");
                    const path = '/login'
                    if (_router.currentRoute.path !== path) _router.push(path)

                    reject(err.response);
                }
                // console.log('Rejecting response')
                reject(err.response);
            });
        }
    );
}
