
    import {
      getAllInventoryLabResults,
      createInventoryLabResult,
      createAllInventoryLabResults,
      updateInventoryLabResult,
      deleteInventoryLabResult
    } from "@/services/inventoryLabResult";

    const state = {
      inventoryLabResults: [],
      processed: false
    };

    const getters = {
      getInventoryLabResults(state) {
        return state.inventoryLabResults;
      },
      inventoryLabResultProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchInventoryLabResults({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllInventoryLabResults(
              params
            )
              .then(response => {
                commit("SET_INVENTORYLABRESULTS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createInventoryLabResult({ commit }, inventoryLabResult) {
          return new Promise((resolve, reject) => {
            createInventoryLabResult(inventoryLabResult)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllInventoryLabResults({ commit }, inventoryLabResults) {
          return new Promise((resolve, reject) => {
            createAllInventoryLabResults(inventoryLabResults)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateInventoryLabResult({ commit }, inventoryLabResult) {
          return new Promise((resolve, reject) => {
            updateInventoryLabResult(inventoryLabResult)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteInventoryLabResult({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteInventoryLabResult(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_INVENTORYLABRESULTS(state, data) {
        state.inventoryLabResults = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    