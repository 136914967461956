
  const dynamicQrCode = {
    path: "/dynamicQrCode",
    name: "dynamicQrCode",
    component: () => import(/* webpackChunkName: "dynamic_qr_code" */ "@/components/dynamicQrCode/DynamicQrCodeIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "dynamic_qr_code" */ "@/components/dynamicQrCode/DynamicQrCodeView.vue"), name: "dynamicQrCodeView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "dynamic_qr_code" */ "@/components/dynamicQrCode/DynamicQrCodeEdit.vue"), name: "dynamicQrCodeEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "dynamic_qr_code" */ "@/components/dynamicQrCode/DynamicQrCodeCreate.vue"), name: "dynamicQrCodeCreate"},
      { path: "worksheet/:uuid?", component: () => import(/* webpackChunkName: "dynamic_qr_code" */ "@/components/dynamicQrCode/WorksheetQRCodeForm.vue"), name: "worksheetQRCodeForm"}
    ],
    meta: { authorize: ['Administrator', 'Manager', 'Factory'], requiresAuth: true }
  };

export default dynamicQrCode;
    