
    import Vue from "vue";

    const END_POINT = "/dynamicQrCode";

    const getDynamicQrCodeById = id => Vue.axios.get(END_POINT + "/by-id/" + id);

    const getDynamicQrCode = uuid => Vue.axios.get(END_POINT + "/" + uuid);

    const getAllDynamicQrCodes = data => Vue.axios.get(END_POINT, {params: data});

    const getMyRecentScans = (data) => Vue.axios.get(END_POINT + "/my-recent-scanned", {params: data});

    const createDynamicQrCode = dynamicQrCode => Vue.axios.post(END_POINT,  dynamicQrCode );

    const createAllDynamicQrCodes = DynamicQrCodes => Vue.axios.post(END_POINT + "/all",  DynamicQrCodes );

    const updateDynamicQrCode = dynamicQrCode => Vue.axios.put(END_POINT  + "/" + dynamicQrCode.id,  dynamicQrCode );

    const deleteDynamicQrCode = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getDynamicQrCode,
      getDynamicQrCodeById,
      getAllDynamicQrCodes,
      getMyRecentScans,
      createDynamicQrCode,
      createAllDynamicQrCodes,
      updateDynamicQrCode,
      deleteDynamicQrCode
    };
    