
    import Vue from "vue";

    const END_POINT = "/lab-element";

    const getLabElement = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllLabElements = data => Vue.axios.get(END_POINT, {params: data});

    const createLabElement = labElement => Vue.axios.post(END_POINT,  labElement );

    const createAllLabElements = LabElements => Vue.axios.post(END_POINT + "/all",  LabElements );

    const updateLabElement = labElement => Vue.axios.put(END_POINT  + "/" + labElement.id,  labElement );

    const deleteLabElement = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getLabElement,
      getAllLabElements,
      createLabElement,
      createAllLabElements,
      updateLabElement,
      deleteLabElement
    };
    