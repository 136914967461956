import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

// DO NOT USE vuex-persist;  crashes app when vuex variable isnt found on local storage...
// import VuexPersistence from "vuex-persist"; 

// console.log("modules: ", modules)

// const vuexLocal = new VuexPersistence({
//   supportCircular: true,
//   storage: window.localStorage,
//   modules: ['navigation', 'inventoryParameters', 'labSearchParameters']
// });

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  // plugins: [vuexLocal.plugin]
});
