
    import {
      getAllInventoryReservations,
      createInventoryReservation,
      createAllInventoryReservations,
      updateInventoryReservation,
      releaseInventoryReservation,
      deleteInventoryReservation
    } from "@/services/inventoryReservation";

    const state = {
      inventoryReservations: [],
      processed: false
    };

    const getters = {
      getInventoryReservations(state) {
        return state.inventoryReservations;
      },
      inventoryReservationProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchInventoryReservations({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllInventoryReservations(
              params
            )
              .then(response => {
                commit("SET_INVENTORYRESERVATIONS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createInventoryReservation({ commit }, inventoryReservation) {
          return new Promise((resolve, reject) => {
            createInventoryReservation(inventoryReservation)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllInventoryReservations({ commit }, inventoryReservations) {
          return new Promise((resolve, reject) => {
            createAllInventoryReservations(inventoryReservations)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateInventoryReservation({ commit }, inventoryReservation) {
          return new Promise((resolve, reject) => {
            updateInventoryReservation(inventoryReservation)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        releaseInventoryReservation({ commit }, {worksheetId, inventoryId, reservedQuantity, inventoryUnitId}) {
          return new Promise((resolve, reject) => {
            releaseInventoryReservation(worksheetId, inventoryId, reservedQuantity, inventoryUnitId)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteInventoryReservation({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteInventoryReservation(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_INVENTORYRESERVATIONS(state, data) {
        state.inventoryReservations = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    