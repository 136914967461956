
    import Vue from "vue";

    const END_POINT = "/inventory-lab-result";

    const getInventoryLabResult = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllInventoryLabResults = data => Vue.axios.get(END_POINT, {params: data});

    const createInventoryLabResult = inventoryLabResult => Vue.axios.post(END_POINT,  inventoryLabResult );

    const createAllInventoryLabResults = inventoryLabResults => Vue.axios.post(END_POINT + "/all",  inventoryLabResults );

    const updateInventoryLabResult = inventoryLabResult => Vue.axios.put(END_POINT  + "/" + inventoryLabResult.id,  inventoryLabResult );

    const deleteInventoryLabResult = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getInventoryLabResult,
      getAllInventoryLabResults,
      createInventoryLabResult,
      createAllInventoryLabResults,
      updateInventoryLabResult,
      deleteInventoryLabResult
    };
    