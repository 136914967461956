<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary darken-3"
    dark
    class="d-print-none"
    style="z-index: 999"
  >
    <v-progress-linear
      :active="isAppLoading"
      :indeterminate="true"
      absolute
      :top="true"
      color="accent darken-1"
    ></v-progress-linear>

    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <v-app-bar-nav-icon @click.prevent.stop="toggleDrawer" />
      <!-- <span class="hidden-sm-and-down">{{$vuetify.lang.t('$vuetify.base.companyName')}}</span> -->
      <span class="hidden-sm-and-down">{{ companyName }}</span>
    </v-toolbar-title>

    <v-spacer />
    <v-btn
      class="d-none"
      data-cy="securemenu"
      color="primary"
      dark
      v-if="currentUser != null"
      >{{ currentUser.firstName }}</v-btn
    >

    <v-btn @click="hideDrawer" dark icon :to="{ name: 'inventorySearch' }"
      ><v-icon>mdi-folder-search-outline</v-icon></v-btn
    >
    <qr-scanner />

    <v-divider class="mx-4" inset vertical></v-divider>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-3" v-bind="attrs" v-on="on" icon>
          <v-chip color="primary">{{ language }}</v-chip>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in languages"
          :key="item.text"
          @click.stop="changeLanguage(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      class="ml-3"
      data-cy="login"
      to="/login"
      v-if="!isLoggedIn"
      icon
      title="Login"
    >
      <v-icon>mdi-login</v-icon>
    </v-btn>
    <v-btn class="ml-3" v-if="isLoggedIn" @click="logout" icon title="Logout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const QrScanner = () => import("@/components/common/QrScanner.vue");

export default {
  data: () => ({
    drawer: null,
    companyName: process.env.VUE_APP_TITLE,
  }),
  components: {
    QrScanner,
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("auth", ["currentUser", "isLoggedIn", "isAdministrator"]),
    languages() {
      return [
        {
          text: "ภาษาไทย",
          value: "th",
        },
        {
          text: "English",
          value: "en",
        },
        {
          text: "简体中文",
          value: "zhHans",
        },
      ];
    },
    language() {
      let lang = localStorage.getItem("locale");
      if (lang === "th") {
        return this.$vuetify.lang.t("$vuetify.language.th");
      } else if (lang === "en") {
        return this.$vuetify.lang.t("$vuetify.language.en");
      } else if (lang === "zhHans") {
        return this.$vuetify.lang.t("$vuetify.language.zhHans");
      } else {
        //default to english
        return this.$vuetify.lang.t("$vuetify.language.en");
      }
    },
  },
  methods: {
    ...mapActions("auth", ["processLogout", "setMyCenters"]),
    ...mapActions("navigation", ["toggleDrawer", "hideDrawer", "setCenter"]),
    logout() {
      this.$axios.post(process.env.VUE_APP_AUTHHOST + "/signout").then(() => {
        this.processLogout();
        delete this.$axios.defaults.headers.common["Authorization"];
        this.$router.push({ name: "Login" });
      });
    },
    changeLanguage(language) {
      this.language = language;
      localStorage.setItem("locale", language);
      window.location.reload();
    },
  },
};
</script>

<style>
</style>