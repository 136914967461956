
    import {
      getAllProcessWorksheets,
      getWorksheetUsers,
      getWorksheetSettlement,
      createProcessWorksheet,
      createAllProcessWorksheets,
      updateProcessWorksheet,
      submitProcessWorksheet,
      deleteProcessWorksheet
    } from "@/services/processWorksheet";

    const state = {
      processWorksheets: [],
      processed: false
    };

    const getters = {
      getProcessWorksheets(state) {
        return state.processWorksheets;
      },
      processWorksheetProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchProcessWorksheets({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllProcessWorksheets(
              params
            )
              .then(response => {
                commit("SET_PROCESSWORKSHEETS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
      fetchWorksheetUsers({ commit }) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getWorksheetUsers()
              .then(response => {
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
      fetchWorksheetSettlement({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getWorksheetSettlement(params)
              .then(response => {
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createProcessWorksheet({ commit }, processWorksheet) {
          return new Promise((resolve, reject) => {
            createProcessWorksheet(processWorksheet)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllProcessWorksheets({ commit }, processWorksheets) {
          return new Promise((resolve, reject) => {
            createAllProcessWorksheets(processWorksheets)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateProcessWorksheet({ commit }, processWorksheet) {
          return new Promise((resolve, reject) => {
            updateProcessWorksheet(processWorksheet)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        submitProcessWorksheet({ commit }, processWorksheet) {
          return new Promise((resolve, reject) => {
            submitProcessWorksheet(processWorksheet)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteProcessWorksheet({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteProcessWorksheet(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_PROCESSWORKSHEETS(state, data) {
        state.processWorksheets = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    