
    import Vue from "vue";

    const END_POINT = "/companyVehicle";

    const getCompanyVehicle = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllCompanyVehicles = data => Vue.axios.get(END_POINT, {params: data});

    const searchCompanyVehicles = data => Vue.axios.get(END_POINT + "/search", {params: data});

    const createCompanyVehicle = companyVehicle => Vue.axios.post(END_POINT,  companyVehicle );

    const createAllCompanyVehicles = CompanyVehicles => Vue.axios.post(END_POINT + "/all",  CompanyVehicles );

    const updateCompanyVehicle = companyVehicle => Vue.axios.put(END_POINT  + "/" + companyVehicle.id,  companyVehicle );

    const deleteCompanyVehicle = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getCompanyVehicle,
      getAllCompanyVehicles,
      searchCompanyVehicles,
      createCompanyVehicle,
      createAllCompanyVehicles,
      updateCompanyVehicle,
      deleteCompanyVehicle
    };
    