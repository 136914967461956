

const state = {
  parameters: {
    products: [],
    hideZero: true,
    threshold: 35, // 35% above min
  },
  loading: false
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  updateParameters({commit}, params) {
    commit('setParameters', params);
  },
};

const getters = {
  parameters: state => {
    return state.parameters;
  },
  isLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
