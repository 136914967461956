import InventoryCostAdjustmentService from "@/services/InventoryCostAdjustmentService.js";
// import moment from "moment";
import axiosObj from "axios";


const state = {
  costAdjustmentService: null,
  loaded: false
};

const mutations = {
  setCostAdjustmentService(state, service) {
    state.loaded = true;
    state.costAdjustmentService = service;
  },
  setLoaded(state, status) {
    state.loaded = status;
  }
};

const actions = {
  // async loadInventoryCostAdj({ commit }, { date }) {
  async loadInventoryCostAdj({ commit, state }) {
    commit('setLoaded', false);

    if(state.costAdjustmentService){
      state.costAdjustmentService.destory();
    }


    // const momentDate = moment(date).endOf("day");
    // const dateTime = momentDate.format("YYYY-MM-DDTHH:mm:ss")
    const momentDate = null;
    
    const startBalanceService = this.state.inventoryBalances
      .startBalanceService;
    const endBalanceService = this.state.inventoryBalances.endBalanceService;
    const eventsService = this.state.inventoryEvents.eventsService;

    //Get all inventory ids in start, end and inventory events
    let startBalanceInventoryIds = startBalanceService && typeof startBalanceService.getInventoryIds !== "undefined" ? startBalanceService.getInventoryIds():[];
    let endBalanceInventoryIds = endBalanceService && typeof endBalanceService.getInventoryIds !== "undefined" ? endBalanceService.getInventoryIds():[];
    let eventsInventoryIds = eventsService ? eventsService.getInventoryIds():[];

    let inventoryIds = Array.from(
      new Set([
        ...startBalanceInventoryIds,
        ...endBalanceInventoryIds,
        ...eventsInventoryIds
      ])
    );

    const inventoryChunks = actions.sliceIntoChunks(inventoryIds, 300);
    const axiosRequests = inventoryChunks.map( chunk => {
        // load all cost adjustment, no cut-off date
        // const data = { date_time: dateTime, inventory_ids: chunk };
        const data = { inventory_ids: chunk };
        return this._vm.$axios.post("inventory/cost-adjustments", data)
    });

    
    return await axiosObj.all(axiosRequests)
      .then(axiosObj.spread((...responses) => {
        let allData = [];
        responses.forEach(resp => {
            if(resp.data){
                const data = resp.data.data;
                allData = allData.concat(data);
            }
        })
        const service = new InventoryCostAdjustmentService(momentDate ? momentDate.toDate():null, allData);
        commit('setCostAdjustmentService', service);
        if(startBalanceService){
          startBalanceService.applyCostAdjustments(service);
        }
        if(endBalanceService && typeof endBalanceService.applyCostAdjustments !== "undefined" ){
          endBalanceService.applyCostAdjustments(service);
        }

        return service;
      }))
    },
    sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }
};

const getters = {
  costAdjustmentService: state => {
    return state.costAdjustmentService;
  },
  isLoaded: state => {
    return state.loaded;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
