import TreeModel from "tree-model";

const state = {
  processTypes: null,
  loading: false
};

const mutations = {
  setProcessTypes(state, list) {
    state.processTypes = list;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  async loadProcessTypes({commit, state}) {
    return await this._vm.$axios
      .get("category/childs", {
        params: {
          code: "PROCESSES"
        }
      })
      .then(response => {
        var data = response.data;
        if (data.code != 200) {
          let message = data.message;
          this._vm.$store.dispatch("messages/addMessage", {
            message,
            messageClass: "danger"
          });
          return;
        } else {
          const rootProcesses = data.data;
          const root = { name: 'root', id: 0, children: rootProcesses }

          const model = new TreeModel();
          const rootCategory = model.parse(root);
          //assign level
          rootCategory.walk( (node) => { 
            const path = node.getPath();
            const hasChildren = node.hasChildren();
            node.model.level = path.length - 1; //exclude root
            node.model.path = path.map(p => p.model.id);
            node.model.hasChildren = hasChildren;
            if(hasChildren) {
              const leafNodes = node.all(function (n) {
                  return !n.hasChildren();
              });
              if(leafNodes && leafNodes.length > 0)
                node.model.leafNodeIds = leafNodes.map( l => l.model.id);
            }
            node.model.state = 'closed';
          } )

          const leafNodes = actions.getAllProcessTypes(rootCategory);
          let allProcessTypes = leafNodes.map(node => node.model);
          allProcessTypes = allProcessTypes.splice(1, allProcessTypes.length)
          allProcessTypes.sort((a, b) => (a.name > b.name) ? 1 : -1)
          commit("setProcessTypes", allProcessTypes)

          return rootCategory;
        }
      });
  },
  getAllProcessTypes(rootNode){

    if(rootNode){
      var leafNodes = rootNode.all(function (node) {
          return node.model.chidren == null || node.model.chidren.length < 1;
      });
      return leafNodes;
    }
    else{
      return [];
    }
  },
};

const getters = {
  processTypes: state => {
    return state.processTypes;
  },
  isAppLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
