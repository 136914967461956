
  const company = {
    path: "/company",
    name: "company",
    component: () => import(/* webpackChunkName: "company" */ "@/components/company/CompanyIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "company" */ "@/components/company/CompanyView.vue"), name: "companyView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "company" */ "@/components/company/CompanyEdit.vue"), name: "companyEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "company" */ "@/components/company/CompanyCreate.vue"), name: "companyCreate"}
    ],
    meta: { authorize: ['Administrator', 'Manager', 'Factory'], requiresAuth: true }
  };

export default company;
    