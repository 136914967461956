
  const processWorksheet = {
    path: "/processWorksheet",
    name: "processWorksheet",
    component: () => import(/* webpackChunkName: "process_worksheet" */ "@/components/processWorksheet/ProcessWorksheetIndex.vue"),
    children: [
      { path: "edit/:id", component: () => import(/* webpackChunkName: "process_worksheet" */ "@/components/processWorksheet/ProcessWorksheetEdit.vue"), name: "processWorksheetEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "process_worksheet" */ "@/components/processWorksheet/ProcessWorksheetCreate.vue"), name: "processWorksheetCreate"},
      { path: "print/:id", component: () => import(/* webpackChunkName: "process_worksheet" */ "@/components/processWorksheet/WorksheetPrintQR.vue"), name: "processWorksheetPrintQR"}
    ],
    meta: { authorize: ['Administrator', 'Manager', 'Factory', 'Factory - Worksheet'], requiresAuth: true }
  };

export default processWorksheet;
    