
    import Vue from "vue";

    const END_POINT = "/page-parameters";

    const getPageParameters = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllPageParameters = data => Vue.axios.get(END_POINT, {params: data});

    const createPageParameters = pageParameters => Vue.axios.post(END_POINT,  pageParameters );

    const createAllPageParameters = PageParameters => Vue.axios.post(END_POINT + "/all",  PageParameters );

    const updatePageParameters = pageParameters => Vue.axios.put(END_POINT  + "/" + pageParameters.id,  pageParameters );

    const deletePageParameters = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getPageParameters,
      getAllPageParameters,
      createPageParameters,
      createAllPageParameters,
      updatePageParameters,
      deletePageParameters
    };
    