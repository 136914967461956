import Vue from "vue";
import axiosObj from "axios";

const END_POINT = "/inventoryReservation";

const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const getReservationByInventoryIds = async (ids) => {
  const inventoryChunks = sliceIntoChunks(ids, 250);
  const axiosRequests = inventoryChunks.map((chunk) => {
    const params = { ids: chunk.join(',') };
    return Vue.axios.get("/inventoryReservation/inventories/", { params });
  });

  return await axiosObj.all(axiosRequests).then(
    axiosObj.spread((...responses) => {
      let allData = [];
      responses.forEach((resp) => {
        if (resp.data) {
          const data = resp.data;
          allData = allData.concat(data);
        }
      });

      return allData;
    })
  );
};

/**
 * Loop through inventory and tag as reserved if reserved by worksheet
 * @param {*} inventories 
 * @returns 
 */
const tagReservedInventories = async (inventories) => {
  if (inventories && inventories.length > 0) {
    const ids = inventories.map((i) => (i.inventoryId ? i.inventoryId : i.id));
    return await getReservationByInventoryIds(ids).then((reservedInventories) => {
      let inventory2QuantityReserved = null;

      // build inventory to reserved quantity map
      if (reservedInventories) {
        inventory2QuantityReserved = reservedInventories.reduce((map, r) => {

          const key = r.inventoryId;
          const reservedQuantity = r.quantity ? r.quantity : 0;
          if (map.get(key)) {
            const totalQuantityReserved = map.get(key) + reservedQuantity;
            map.set(key, totalQuantityReserved);
          } else {
            map.set(key, reservedQuantity);
          }

          return map;
        }, new Map());
      }

      //loop through inventory to tag as reserved
      inventories.forEach((i) => {
        const inventoryId = i.inventoryId ? i.inventoryId : i.id;

        if (i.unitDetails && i.unitDetails.length > 0) {
          // let reservedQuantity = 0;
          let reservedCount = 0;
          //loop through all inventory units and tag as reserved
          i.unitDetails.forEach((u) => {
            const found =
              reservedInventories.findIndex((r) => r.inventoryUnitId == u.id) >=
              0;
            if (found) {
              u.reserved = true;
              // reservedQuantity += (u.amountPerUnit ? u.amountPerUnit:0);
              reservedCount++;
            }
          });
          if (reservedCount) {
            i.reservedUnitCount = reservedCount;
          }

          // i.reservedQuantity = reservedQuantity;
        }

        const reservedQuantity = inventory2QuantityReserved.get(inventoryId);

        if (reservedQuantity && reservedQuantity >= 0) {
          i.reserved = true;
        }

        i.reservedQuantity = (reservedQuantity ? reservedQuantity : 0);
        const reservations = reservedInventories.filter((r) => r.inventoryId == inventoryId);
        i.reservedByWorksheets = reservations;
      });

      return inventory2QuantityReserved;
    });
  }

  return null;
}

const getInventoryReservation = (id) => Vue.axios.get(END_POINT + "/" + id);

const getAllInventoryReservations = (data) =>
  Vue.axios.get(END_POINT, { params: data });

const createInventoryReservation = (inventoryReservation) =>
  Vue.axios.post(END_POINT, inventoryReservation);

const createAllInventoryReservations = (InventoryReservations) =>
  Vue.axios.post(END_POINT + "/all", InventoryReservations);

const updateInventoryReservation = (inventoryReservation) =>
  Vue.axios.put(
    END_POINT + "/" + inventoryReservation.id,
    inventoryReservation
  );

const releaseInventoryReservation = (
  worksheetId,
  inventoryId,
  reservedQuantity,
  inventoryUnitId
) =>
  Vue.axios.post(END_POINT + "/release", {
    worksheetId,
    inventoryId,
    reservedQuantity,
    inventoryUnitId,
  });

const deleteInventoryReservation = (id) =>
  Vue.axios.delete(END_POINT + "/" + id);

export {
  tagReservedInventories,
  getReservationByInventoryIds,
  getInventoryReservation,
  getAllInventoryReservations,
  createInventoryReservation,
  createAllInventoryReservations,
  updateInventoryReservation,
  releaseInventoryReservation,
  deleteInventoryReservation,
};
