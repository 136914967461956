
    import Vue from "vue";

    const END_POINT = "/label-gen";

    const getLabelGen = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllLabelGens = data => Vue.axios.get(END_POINT, {params: data});

    const createLabelGen = labelGen => Vue.axios.post(END_POINT,  labelGen );

    const createAllLabelGens = LabelGens => Vue.axios.post(END_POINT + "/all",  LabelGens );

    const updateLabelGen = labelGen => Vue.axios.put(END_POINT  + "/" + labelGen.id,  labelGen );

    const deleteLabelGen = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getLabelGen,
      getAllLabelGens,
      createLabelGen,
      createAllLabelGens,
      updateLabelGen,
      deleteLabelGen
    };
    