
    import {
      getAllWorksheetTypeDefinitions,
      createWorksheetTypeDefinition,
      createAllWorksheetTypeDefinitions,
      updateWorksheetTypeDefinition,
      deleteWorksheetTypeDefinition
    } from "@/services/worksheetTypeDefinition";

    const state = {
      worksheetTypeDefinitions: [],
      processed: false
    };

    const getters = {
      worksheetTypeDefinitions(state) {
        return state.worksheetTypeDefinitions;
      },
      worksheetTypeDefinitionProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchWorksheetTypeDefinitions({ commit }, params) {

        if(!params){
          params = { page: 0, size: 1000 };
        }
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllWorksheetTypeDefinitions(
              params
            )
              .then(response => {
                commit("SET_WORKSHEETTYPEDEFINITIONS", response.data.content);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createWorksheetTypeDefinition({ commit }, worksheetTypeDefinition) {
          return new Promise((resolve, reject) => {
            createWorksheetTypeDefinition(worksheetTypeDefinition)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllWorksheetTypeDefinitions({ commit }, worksheetTypeDefinitions) {
          return new Promise((resolve, reject) => {
            createAllWorksheetTypeDefinitions(worksheetTypeDefinitions)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateWorksheetTypeDefinition({ commit }, worksheetTypeDefinition) {
          return new Promise((resolve, reject) => {
            updateWorksheetTypeDefinition(worksheetTypeDefinition)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteWorksheetTypeDefinition({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteWorksheetTypeDefinition(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_WORKSHEETTYPEDEFINITIONS(state, data) {
        state.worksheetTypeDefinitions = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    