

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
/*  @param {string} s - an ISO 8001 format date and time string
 **                      with all components, e.g. 2015-11-24T19:40:00
 **  @returns {Date} - Date instance from parsing the string. May be NaN.
 */
const parseISOLocal = (s) => {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}
export default class EventsService {
  constructor(events) {
    this.instanceId = Date.now();
    this._summarize(events);
  }

  _summarize(events) {
    let summary = {};
    if (!events || events.length < 1) {
      return summary;
    }

    this.group2Summary = new Map();

    const minMax = this._calculateMinMaxDate(events);
    this.minTime = minMax.min;
    this.maxTime = minMax.max;

    this.inventoryIds = this._parseUniqueInventoryIds(events);
    this.product2Events = this._groupEventsByProducts(events);
    this.eventProductIds = Object.keys(this.product2Events)
    return summary;
  }

  _calculateMinMaxDate(events) {
    if (events && events.length > 0) {
      let minDate;
      let maxDate;

      events.forEach(event => {
        const eventTime = parseISOLocal(event.eventTime);
        if (!minDate || eventTime < minDate) {
          minDate = eventTime;
        }
        if (!maxDate || eventTime > maxDate) {
          maxDate = eventTime;
        }
      });

      return { min: minDate, max: maxDate };
    } else {
      return { min: null, max: null };
    }
  }


  _parseUniqueInventoryIds(events) {
    if(events && events.length > 0){
      return events.map(e => e.inventoryId);
    }
    else{
      return []
    }
  }

  _groupEventsByProducts(events) {
    
    if(events && events.length > 0){
      return events.reduce( (product2Events, event) => {
        const pEvents = product2Events[event.productId];
        if(pEvents == null){
          product2Events[event.productId] = [event];
        }
        else{
          product2Events[event.productId].push(event);
        }
        return product2Events;
      }, {});

    }
    else{
      return {}
    }
  }
  
  getDateRange() {
    return {minTime: this.minTime, maxTime: this.maxTime}
  }

  getInventoryIds() {
    return this.inventoryIds ? this.inventoryIds:[];
  }

  getEventProductIds() {
    return this.eventProductIds;
  }

  getGroupedEvents(group, costAdjustService){
    if(!this.group2Summary){
      this.group2Summary = new Map();
    }
    
    const key = group.toString();
    const existing = this.group2Summary.get(key);
    //check cache before running calculation
    if(existing && existing.lastChecked){
      return existing;
    }

    const groupEvents = this._getGroupEvents(group);
    const matchingEvents = groupEvents.filter( event => this._matchEventGroup(event, group));
    const calculated = this._rollupEvents(matchingEvents, costAdjustService);

    calculated.product = group.product.name;
    calculated.productId = group.product.id;
    calculated.size = calculated.details && calculated.details.length > 0 ? calculated.details.length : 0;
    calculated.details = this._groupEventsByType(calculated.details);
    calculated.key = key;

    this.group2Summary.set(key, calculated);
    // console.log('group key: '+  key)
    // console.log('calculating group: ', group);

    return calculated;
  }

  _getGroupEvents(group){
    let productIds = [];
    if(group.product && group.product.hasChildren){
      productIds = group.product.leafNodeIds;
    }
    else{
      productIds = [group.product.id];
    }

    let matchingEvents = [];
    productIds.forEach( productId => {
      let events = null;  
      if(productId && this.product2Events){
        events = this.product2Events[productId];
      }

      if(events && events.length > 0){
        matchingEvents = matchingEvents.concat(events);
      }
    })

    return matchingEvents;
  }

  _matchEventGroup(event, group){

    let matched = false;
    if(event && group){
      let matchedOnProduct = false;
      if(group.product && group.product.hasChildren){
        matchedOnProduct = group.product.leafNodeIds.includes(event.productId);
      }
      else{
        matchedOnProduct = group.product.id == event.productId;
      }

      if(matchedOnProduct){
        const eventTime = parseISOLocal(event.eventTime);
        const groupStart = group.startTime instanceof Date ? group.startTime:parseISOLocal(group.startTime);
        const groupEnd = group.endTime instanceof Date ? group.endTime:parseISOLocal(group.endTime);
        const matchedOnTime = eventTime >= groupStart && eventTime <= groupEnd;

        // console.log(eventTime.format('YYYY-MM-DD HH:mm') + "is betweent " + group.startTime + " and " + group.endTime + ": "+ matchedOnTime);

        if(matchedOnTime){
          const matchedOnEventType = group.eventTypes == null || group.eventTypes.includes(event.type);
          matched = matchedOnEventType;
        }
      }
    }

    return matched;
  }

  _rollupEvents(events, adjService){
    if(events && events.length > 0){
      return events.reduce(function(summary, item) {  
        const eventTime = item.eventTime;
        const unit = item.unit;
        const companyId = item.companyId;
        const companyName = item.companyName;
        const processId = item.processId;
        const processCode = item.processCode;
        const processTypeId = item.processTypeId;
        const purchaseId = item.purchaseId;
        const adjustmentId = item.adjustmentId;
        const salesOrderId = item.salesOrderId;
        const event = item.event;
        const type = item.type;
        const inventoryId = item.inventoryId;
        const isAdjustment = item.isAdjustment;
        const inventoryOriginalValue = item.inventoryOriginalValue;
        const inventoryOriginalWeight = item.inventoryOriginalWeight;
        const product = item.product;
        const saleItemUnitPrice = item.saleItemUnitPrice;
        const salePriceBaseUnit = item.salePriceBaseUnit;
        const salesUnitAdjustment = item.salesUnitAdjustment;
        const salesWeightAdjustment = item.salesWeightAdjustment;
  
        const detail = {
          time: eventTime,
          quantity: item.quantity,
          unit,
          processId,
          processCode,
          processTypeId,
          adjustmentId,
          purchaseId,
          salesOrderId,
          event,
          type,
          inventoryId,
          inventoryOriginalValue,
          inventoryOriginalWeight,
          value: 0,
          costAdjustment: 0,
          companyId,
          companyName,
          product,
          saleItemUnitPrice,
          salePriceBaseUnit,
          salesUnitAdjustment,
          salesWeightAdjustment
        }
        if(event && adjService){
          
          let inventoryCostAdjustment = adjService.getAdjustmentCostOnDate(detail.inventoryId);
          detail.costAdjustment = inventoryCostAdjustment ? inventoryCostAdjustment : 0;
          const inventoryCost = detail.inventoryOriginalValue + detail.costAdjustment;
          detail.unitCost = inventoryCost / detail.inventoryOriginalWeight;
          detail.value = detail.quantity * detail.unitCost; // remaining value
          detail.hasAppliedCostAdjustment = true;
        }
        //No cost adjustment available for event
        else{
          detail.unitCost = detail.inventoryOriginalValue / detail.inventoryOriginalWeight;
          detail.value = detail.quantity * detail.unitCost; // remaining value
          detail.hasAppliedCostAdjustment = false;
        }
  
        if (summary == null) {
          summary = {
            totalQuantity: 0,
            totalUnit: 0,
            totalValue: 0,
            details: [],
            hasAdjustment: false,
            lastChecked: new Date()
          }
        }
  
        if (isAdjustment) {
          summary.hasAdjustment = true;
        }
  
        summary.totalQuantity += item.quantity ? item.quantity : 0;
        summary.totalValue += detail.value ? detail.value : 0;
        summary.totalUnit += item.unit ? item.unit : 0;
        summary.details.push(detail);
  
        return summary;
      }, null);
    }
    else{
      return {lastChecked: new Date()}
    }
  }

  _groupEventsByType(events){
    if(!events || events.length < 1) return {};

    return events.reduce((groups, event) => {
      if (groups[event.type]) {
        groups[event.type].events.push(event);
      } else {
        groups[event.type] = {
          events: [event],
        };
      }

      if (event.type == "inputs" || event.type == "outputs") {
        if (groups["process"]) {
          groups["process"].events.push(event);
        } else {
          groups["process"] = { events: [event] };
        }
      }

      return groups;
    }, {});
  }

  destory(){
    delete this.group2Summary;
    delete this.minTime;
    delete this.maxTime;

    delete this.inventoryIds;
    delete this.product2Events;
    delete this.eventProductIds;
  }
}
