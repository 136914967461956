import Vue from "vue";

const state = {
  categories: [],
};

const getters = {
  categories(state) {
    return state.categories;
  },
};

const actions = {
  fetchCategory({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      let cached = null;
      if (state.categories && state.categories.length > 0) {
        cached = state.categories.find((c) => c.id == id);
      } 

      if (cached) {
        resolve(cached);
      } else {
        Vue.axios.get("/category/" + id)
          .then((response) => {
            const category = response.data;
            commit("ADD_CATEGORY", category);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

const mutations = {
  ADD_CATEGORY(state, data) {
    if(data){
      const id = data.id;
      const category = state.categories.find((c) => c.id == id);
      if(!category){
        state.categories.push(data);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
