
  const labelGen = {
    path: "/labelGen",
    name: "labelGen",
    component: () => import(/* webpackChunkName: "label_gen" */ "@/components/labelGen/LabelGenIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "label_gen" */ "@/components/labelGen/LabelGenView.vue"), name: "labelGenView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "label_gen" */ "@/components/labelGen/LabelGenEdit.vue"), name: "labelGenEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "label_gen" */ "@/components/labelGen/LabelGenCreate.vue"), name: "labelGenCreate"}
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default labelGen;
    