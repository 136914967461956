const state = {
  view: {
    drawer: true,
    dialog: false
  },
  loading: false
}

const mutations = {
  setViewDrawer(state, drawer) {
    state.view.drawer = drawer;
  },
  setViewDialog(state, dialog) {
    state.view.dialog = dialog
  },
  setAppLoading() {
    state.loading = true;
  },
  setAppDoneLoading() {
    state.loading = false;
  }
}

const actions = {
  toggleDrawer({ commit, state }) {
    commit('setViewDrawer', !state.view.drawer);
  },
  hideDrawer({ commit }) {
    commit('setViewDrawer', false);
  },
  showDrawer({ commit }) {
    commit('setViewDrawer', true);
  },
  showDialog({ commit }){
    commit('setViewDialog', true)
  },
  hideDialog({ commit }){
    commit('setViewDialog', false)
  },
  appLoading({ commit }){
    commit('setAppLoading');
  },
  stopAppLoading({commit}){
    commit('setAppDoneLoading');
  }
}

const getters = {
	navState: (state) => {
		return state.view;
  },
  isAppLoading: (state) => {
    return state.loading;
  }
}

export default {
	state,
	mutations,
  actions,
  getters
}

