
    import {
      getAllProductSpecs,
      createProductSpecs,
      createAllProductSpecs,
      updateProductSpecs,
      deleteProductSpecs
    } from "@/services/productSpecs";

    const state = {
      productSpecs: [],
      processed: false
    };

    const getters = {
      getProductSpecs(state) {
        return state.productSpecs;
      },
      productSpecsProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchProductSpecs({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllProductSpecs(
              params
            )
              .then(response => {
                commit("SET_PRODUCTSPECS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createProductSpecs({ commit }, productSpecs) {
          return new Promise((resolve, reject) => {
            createProductSpecs(productSpecs)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllProductSpecs({ commit }, productSpecs) {
          return new Promise((resolve, reject) => {
            createAllProductSpecs(productSpecs)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateProductSpecs({ commit }, productSpecs) {
          return new Promise((resolve, reject) => {
            updateProductSpecs(productSpecs)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteProductSpecs({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteProductSpecs(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_PRODUCTSPECS(state, data) {
        state.productSpecs = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    