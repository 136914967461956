
    import {
      getAllAccountClosings,
      createAccountClosing,
      createAllAccountClosings,
      updateAccountClosing,
      deleteAccountClosing
    } from "@/services/accountClosing";

    const state = {
      accountClosings: [],
      processed: false
    };

    const getters = {
      getAccountClosings(state) {
        return state.accountClosings;
      },
      accountClosingProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchAccountClosings({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllAccountClosings(
              params
            )
              .then(response => {
                commit("SET_ACCOUNTCLOSINGS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAccountClosing({ commit }, accountClosing) {
          return new Promise((resolve, reject) => {
            createAccountClosing(accountClosing)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllAccountClosings({ commit }, accountClosings) {
          return new Promise((resolve, reject) => {
            createAllAccountClosings(accountClosings)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateAccountClosing({ commit }, accountClosing) {
          return new Promise((resolve, reject) => {
            updateAccountClosing(accountClosing)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteAccountClosing({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteAccountClosing(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_ACCOUNTCLOSINGS(state, data) {
        state.accountClosings = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    