
    import Vue from "vue";

    const END_POINT = "/product-specs-element";

    const getProductSpecsElement = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllProductSpecsElements = data => Vue.axios.get(END_POINT, {params: data});

    const createProductSpecsElement = productSpecsElement => Vue.axios.post(END_POINT,  productSpecsElement );

    const createAllProductSpecsElements = ProductSpecsElements => Vue.axios.post(END_POINT + "/all",  ProductSpecsElements );

    const updateProductSpecsElement = productSpecsElement => Vue.axios.put(END_POINT  + "/" + productSpecsElement.id,  productSpecsElement );

    const deleteProductSpecsElement = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getProductSpecsElement,
      getAllProductSpecsElements,
      createProductSpecsElement,
      createAllProductSpecsElements,
      updateProductSpecsElement,
      deleteProductSpecsElement
    };
    