
    import {
      getAllDynamicQrCodes,
      getMyRecentScans,
      createDynamicQrCode,
      createAllDynamicQrCodes,
      updateDynamicQrCode,
      deleteDynamicQrCode
    } from "@/services/dynamicQrCode";

    const state = {
      dynamicQrCodes: [],
      processed: false
    };

    const getters = {
      getDynamicQrCodes(state) {
        return state.dynamicQrCodes;
      },
      dynamicQrCodeProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchDynamicQrCodes({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllDynamicQrCodes(
              params
            )
              .then(response => {
                commit("SET_DYNAMICQRCODES", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        fetchMyScannedQrCodes({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getMyRecentScans(params)
              .then(response => {
                commit("SET_DYNAMICQRCODES", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createDynamicQrCode({ commit }, dynamicQrCode) {
          return new Promise((resolve, reject) => {
            createDynamicQrCode(dynamicQrCode)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllDynamicQrCodes({ commit }, dynamicQrCodes) {
          return new Promise((resolve, reject) => {
            createAllDynamicQrCodes(dynamicQrCodes)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateDynamicQrCode({ commit }, dynamicQrCode) {
          return new Promise((resolve, reject) => {
            updateDynamicQrCode(dynamicQrCode)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteDynamicQrCode({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteDynamicQrCode(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_DYNAMICQRCODES(state, data) {
        state.dynamicQrCodes = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    