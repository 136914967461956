<template>
  <v-app id="inspire">
    <v-banner v-if="deferredPrompt" color="primary" dark class="text-left mt-14">
      <div class="mt-2">
        {{ $vuetify.lang.t("$vuetify.home.installAppPrompt", title) }}
      </div>
      <template v-slot:actions>
        <v-btn text @click="dismiss">{{ $vuetify.lang.t("$vuetify.home.dismiss") }}</v-btn>
        <v-btn text @click="install">{{ $vuetify.lang.t("$vuetify.home.install") }}</v-btn>
      </template>
    </v-banner>
    <Sidebar class="d-print-none" v-if="showMenu" />
    <Header class="d-print-none" v-if="showMenu" />
    <v-main class="print-mode">
      <message-component></message-component>
      <router-view :key="$route.fullPath"></router-view>

      <v-snackbar
        :timeout="-1"
        :multi-line="true"
        :value="true"
        v-if="refreshSnack"
        bottom
        color="success"
        center
        class="mb-7"
      >
      <div>{{ $vuetify.lang.t("$vuetify.base.refreshMsg") }}</div> 
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
         class="mx-md-2" text @click="refreshPage()">{{ $vuetify.lang.t("$vuetify.base.refresh") }}</v-btn>
      </template>
    </v-snackbar>

    </v-main>
    <div class="d-print-none text-caption text-right font-weight-light">
      Version {{ appVersion }} - {{ appRevisionDate }}
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageComponent from "@/components/common/MessageComponent.vue";
import layouts from "@/components/layout/";
import setupInterceptor from "./config/interceptorsConfig.js";
import Cookies from "js-cookie";

export default {
  components: {
    ...layouts,
    MessageComponent,
  },
  props: {
    source: String,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    isLoginPage() {
      return this.$route.name === "Login";
    },
    showMenu() {
      return (
        !this.isLoginPage && this.currentUser && this.currentUser.uId != null
      );
    },
  },
  data: () => ({
    dialog: false,
    refreshSnack: false,
    env: process.env.NODE_ENV,
    baseUrl: process.env.VUE_APP_BASE_URL,
    title: process.env.VUE_APP_TITLE,
    appVersion: process.env.GIT_VERSION,
    appRevisionDate: process.env.GIT_LASTCOMMITDATETIME,
    deferredPrompt: null
  }),
  methods: {
    ...mapActions("navigation", ["appLoading"]),
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 }); //do not prompt install for 15 days
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    showRefreshUI (e) {
      const registration = e.detail;
      console.log(registration);

      // this.refreshSnack = true;
      // My code to show the refresh UI banner/snackbar goes here.
    },
    refreshPage () {
      location.reload();
    }
  },
  created: function () {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });

    setupInterceptor(this.$axios, this.$store, this.$router);
  },
  beforeDestroy () {
    document.removeEventListener('swUpdated', this.showRefreshUI);
  },
  mounted() {
    document.addEventListener('swUpdated', this.showRefreshUI);
  },
};
</script>
<style>
.v-navigation-drawer {
  z-index: 1000 !important;
}

@media print {
  .print-mode{
    padding-top: 0px !important;
  }
}
</style>
