
    import Vue from "vue";

    const END_POINT = "/company";

    const getCompany = id => Vue.axios.get(END_POINT + "/" + id);

    const getOwnCompany = () => Vue.axios.get(END_POINT + "/own");

    const getAllCompanies = data => Vue.axios.get(END_POINT, {params: data});

    const getAllCustomers = data => Vue.axios.get(END_POINT +"/customers", {params: data});

    const getAllSuppliers = data => Vue.axios.get(END_POINT +"/suppliers", {params: data});

    const createCompany = company => Vue.axios.post(END_POINT,  company );

    const createAllCompanies = Companies => Vue.axios.post(END_POINT + "/all",  Companies );

    const updateCompany = company => Vue.axios.put(END_POINT  + "/" + company.id,  company );

    const deleteCompany = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getCompany,
      getOwnCompany,
      getAllCompanies,
      getAllCustomers,
      getAllSuppliers,
      createCompany,
      createAllCompanies,
      updateCompany,
      deleteCompany
    };
    