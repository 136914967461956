import Vue from 'vue'
import './plugins/axios'
import './filters/currency'
import './filters/customFilters'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import './assets/styles/app.scss'
// import LoadScript from 'vue-plugin-load-script';
import gaaVueLib from 'gaa-vue-lib';
import VueQriously from 'vue-qriously'
import VueCompositionAPI from '@vue/composition-api'
import { Chart, registerables } from "chart.js";
import './registerServiceWorker'
import VueGtag from "vue-gtag";

if(process.env.VUE_APP_GA_MEASUREMENT_ID && process.env.VUE_APP_GA_MEASUREMENT_ID.startsWith('G-')){
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID }
  }, router);
}

Chart.register(...registerables);
Vue.use(VueCompositionAPI)
// Vue.use(LoadScript);
Vue.use(VueQriously)
Vue.use(gaaVueLib, {
    store,
    excludeModules: ['auth', 'terminology']
});

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV==='production' ? false:true;
Vue.config.debug = process.env.NODE_ENV==='production' ? false:true;
Vue.config.silent = process.env.NODE_ENV==='production' ? false:true;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    // Remove the loading screen when Vue is mounted
    const loader = document.getElementById('app-loading');
    if (loader) {
      loader.remove();
    }
  },
}).$mount('#app')