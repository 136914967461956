
  const productSpecsElement = {
    path: "/productSpecsElement",
    name: "productSpecsElement",
    component: () => import(/* webpackChunkName: "product_specs_element" */ "@/components/productSpecsElement/ProductSpecsElementIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "product_specs_element" */ "@/components/productSpecsElement/ProductSpecsElementView.vue"), name: "productSpecsElementView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "product_specs_element" */ "@/components/productSpecsElement/ProductSpecsElementEdit.vue"), name: "productSpecsElementEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "product_specs_element" */ "@/components/productSpecsElement/ProductSpecsElementCreate.vue"), name: "productSpecsElementCreate"}
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default productSpecsElement;
    