
    import Vue from "vue";

    const END_POINT = "/accountClosing";

    const getAccountClosing = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllAccountClosings = data => Vue.axios.get(END_POINT, {params: data});

    const createAccountClosing = accountClosing => Vue.axios.post(END_POINT,  accountClosing );

    const createAllAccountClosings = AccountClosings => Vue.axios.post(END_POINT + "/all",  AccountClosings );

    const updateAccountClosing = accountClosing => Vue.axios.put(END_POINT  + "/" + accountClosing.id,  accountClosing );

    const deleteAccountClosing = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getAccountClosing,
      getAllAccountClosings,
      createAccountClosing,
      createAllAccountClosings,
      updateAccountClosing,
      deleteAccountClosing
    };
    