import EventsService from "@/services/EventsService.js";
import moment from 'moment';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

const state = {
  eventsService: null,
  eventsDateRange: [],
  loading: false
};

const mutations = {
  setEventsService(state, service) {
    state.eventsService = service;
  },
  setEventsDateRange(state, dateRange) {
    state.eventsDateRange = dateRange;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  async loadInventoryEvents({commit, state}, {startDate, endDate, types, processTypes, customers, suppliers}) {
    if(state.eventsService){
      console.log('destory events service');
      state.eventsService.destory();
    }
    
    const startTime = new Date(startDate.setHours(0, 0, 0, 0));
    const formattedStartTime = moment(startTime).format("YYYY-MM-DDTHH:mm:ss")

    //set end date to start date if not provided
    if(!endDate){
      endDate = startDate;
    }

    //set end time as the end of previous day
    const endTime = new Date(endDate.setHours(23, 59, 59, 999));
    const formattedEndTime = moment(endTime).format("YYYY-MM-DDTHH:mm:ss")
    
    const data = { 
        start_time: formattedStartTime, 
        end_time: formattedEndTime, 
        event_types: types, 
        process_type_ids: processTypes,
        customer_ids: customers,
        supplier_ids: suppliers};
        
    return await this._vm.$axios
      .post("inventory/events", data )
      .then(result => {
        const loadedEvents = result.data.data;
        const eventsService = new EventsService(loadedEvents);
        const eventsDateRange = eventsService.getDateRange();

        state.eventsService = eventsService;
        state.eventsDateRange = eventsDateRange;

        if(loadedEvents && loadedEvents.length > 0){
          loadedEvents.forEach(e => {
            e.time = e.eventTime;
          })
        }

        return loadedEvents;
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  },

};

const getters = {
  eventsService: state => {
    return state.eventsService;
  },
  eventsDateRange: state => {
    return state.eventsDateRange;
  },
  isLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
