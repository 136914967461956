
  const inventoryReservation = {
    path: "/inventoryReservation",
    name: "inventoryReservation",
    component: () => import(/* webpackChunkName: "inventory_reservation" */ "@/components/inventoryReservation/InventoryReservationIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "inventory_reservation" */ "@/components/inventoryReservation/InventoryReservationView.vue"), name: "inventoryReservationView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "inventory_reservation" */ "@/components/inventoryReservation/InventoryReservationEdit.vue"), name: "inventoryReservationEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "inventory_reservation" */ "@/components/inventoryReservation/InventoryReservationCreate.vue"), name: "inventoryReservationCreate"}
    ],
    meta: { authorize: ['Administrator', 'Manager', 'Factory'], requiresAuth: true }
  };

export default inventoryReservation;
    