
  const companyVehicle = {
    path: "/companyVehicle",
    name: "companyVehicle",
    component: () => import(/* webpackChunkName: "company_vehicle" */ "@/components/companyVehicle/CompanyVehicleIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "company_vehicle" */ "@/components/companyVehicle/CompanyVehicleView.vue"), name: "companyVehicleView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "company_vehicle" */ "@/components/companyVehicle/CompanyVehicleEdit.vue"), name: "companyVehicleEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "company_vehicle" */ "@/components/companyVehicle/CompanyVehicleCreate.vue"), name: "companyVehicleCreate"}
    ],
    meta: { authorize: ['Administrator', 'Manager', 'Factory'], requiresAuth: true }
  };

export default companyVehicle;
    