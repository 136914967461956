import Vue from "vue";
import moment from "moment";
import 'moment/min/locales';

let lang = localStorage.getItem("locale");
lang = lang === "zhHans" ? 'zh_cn':lang;
moment.locale(lang);

Vue.filter("formatDate", date => {
  if (date) {
    if(lang === 'zh_cn'){
      return moment(date).format("MMM Do");
    }
    else{
      return moment(date).format("DD MMM");
    }
  } else {
    return "n/a";
  }
});

Vue.filter("formatDateYear", date => {
  if (date) {
    if(lang === 'zh_cn'){
      return moment(date).format("MMM Do, YYYY");
    }
    else{
      return moment(date).format("DD MMM, YYYY");
    }
  } else {
    return "n/a";
  }
});

Vue.filter("formatDateTime", date => {
  if (date) {
    if(lang === 'zh_cn'){
      return moment(date).format("MMM Do hh:mm A");
    }
    else{
      return moment(date).format("DD MMM hh:mm A");
    }
  } else {
    return "n/a";
  }
});

Vue.filter("formatTime", date => {
  if (date) {
    return moment(date).format("hh:mm A");
  } else {
    return "n/a";
  }
});

Vue.filter("formatDateTimeYear", date => {
  if (date) {
    if(lang === 'zh_cn'){
      return moment(date).format("MMM Do hh:mm A, YYYY");
    }
    else{
      return moment(date).format("DD MMM hh:mm A, YYYY");
    }
  } else {
    return "n/a";
  }
});

Vue.filter("formatLocalDateTime", date => {
  if (date) {

    const stillUtc = moment.utc(date).toDate();
    if(lang === 'zh_cn'){
      return moment(stillUtc).local().format("MMM Do hh:mm A");
    }
    else{
      return moment(stillUtc).local().format("DD MMM hh:mm A");
    }

  } else {
    return "n/a";
  }
});

Vue.filter("formatNumber", val => {
  if (val) {
    return val.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    return "0";
  }
});

Vue.filter("formatInteger", val => {
  if (val) {
    return val.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  } else {
    return "0";
  }
});

Vue.filter("formatPercent", (val, digits = 0) => {
  if (val !== null && val !== undefined) {
    const formattedPercent = (parseFloat(val * 100)).toFixed(digits);
    return `${formattedPercent}%`;
  } else {
    return "0";
  }
});