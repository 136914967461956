/*  @param {string} s - an ISO 8001 format date and time string
 **                      with all components, e.g. 2015-11-24T19:40:00
 **  @returns {Date} - Date instance from parsing the string. May be NaN.
 */
 const parseISOLocal = (s) => {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}
export default class InventoryCostAdjustmentService {
  constructor(dateTime, costAjustments) {

    this.dateTime = dateTime;
    this._summarize(costAjustments);
    
  }

  /**
   * Build a map of inventory to its adjustment history
   * @param {*} costAjustments 
   */
  _summarize(costAjustments) {
    this.data = this._groupByInventory(costAjustments);
  }

  _groupByInventory(list) {
    return list.reduce(function(res, item) {
      if (!res[item.inventoryId]) {
        res[item.inventoryId] = {
          inventoryId: item.inventoryId,
          count: 0,
          amount: 0,
          adjustments: []
        };
        list.push(res[item.product]);
      }

      res[item.inventoryId].count++;
      res[item.inventoryId].amount += item.amount;
      res[item.inventoryId].adjustments.push(item);

      return res;
    }, {});
  }

  // /**
  //  * Get the latest inventory cost adjustment for the inventory 
  //  * @param {*} inventoryId 
  //  * @returns 
  //  */
  // getAdjustmentCost(inventoryId) {
  //   const adjAmount = this.data[inventoryId].amount;
  //   return {date: this.dateTime, amount: adjAmount}
  // }

  /**
   * Calculate the cost adjustment for a given time on an inventory
   * @param {*} inventoryId 
   * @param {Date} dateTime Must be a Date object
   * @returns 
   */
  getAdjustmentCostOnDate(inventoryId) {

    let adjAmount = 0;
    const inventoryAdjustments = this.data[inventoryId];
   
    if(inventoryAdjustments){
      // const adjustmentCutoffDate = dateTime ? parseISOLocal(dateTime):null;

      // console.log('found cost adj on inventory id: ', inventoryId, inventoryAdjustments);
      const adjustments = inventoryAdjustments.adjustments;
      if(adjustments && adjustments.length > 0){
        adjAmount += adjustments.reduce( (sum, item) => {
            // // only include cost adjustment at and before dateTime
            // const isAfter = adjustmentCutoffDate != null && parseISOLocal(item.adjustmentTime) > adjustmentCutoffDate;

            // if(isAfter){
            //   //skip cost adjustment if adjustment time is after
            //   // console.debug(item.adjustmentTime + ' is after' + dateTime + "; skipping adjustment")
            //   return sum;
            // }
            // else{
            //   return sum += item.amount;
            // }

            return sum += item.amount;
          }, 0);
        }
      }

      return adjAmount;
  }


  // getCostAdjustmentDetails(inventoryId, dateTime) {
  getCostAdjustmentDetails(inventoryId) {

    // Do not filter by dateTime (logic changed on 01/27/2022)
    // const adjustmentCutoffDate = dateTime ? parseISOLocal(dateTime):null;
    const adjustmentCutoffDate = null;
    const inventoryAdjustments = this.data[inventoryId];
    if(inventoryAdjustments && inventoryAdjustments.adjustments.length > 0){
      if(adjustmentCutoffDate){
        return inventoryAdjustments.adjustments.filter((adjustment) => parseISOLocal(adjustment.adjustmentTime) <= adjustmentCutoffDate);
      }
      else{
        return inventoryAdjustments.adjustments;
      }
    }
    else{
      return null;
    }
  }

  destory(){
    delete this.data;
  }
}
