
  const productSpecs = {
    path: "/productSpecs",
    name: "productSpecs",
    component: () => import(/* webpackChunkName: "product_specs" */ "@/components/productSpecs/ProductSpecsIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "product_specs" */ "@/components/productSpecs/ProductSpecsView.vue"), name: "productSpecsView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "product_specs" */ "@/components/productSpecs/ProductSpecsEdit.vue"), name: "productSpecsEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "product_specs" */ "@/components/productSpecs/ProductSpecsCreate.vue"), name: "productSpecsCreate"}
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default productSpecs;
    