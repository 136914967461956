

const state = {
  states: {
    productId: null,
    dateRange: null,
    search: null,
    selectedSort: 0,
  },
  loading: false
};

const mutations = {
  setStates(state, states) {
    state.states = states;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  updateStates({commit}, params) {
    commit('setStates', params);
  },
  resetInventorySelectionStates({commit}){
    const params = {
      productId: null,
      dateRange: null,
      search: null,
      selectedSort: 0,
    }
    commit('setStates', params);
  }
};

const getters = {
  states: state => {
    return state.states;
  },
  isLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
