

const state = {
  parameters: {
    inventoryStatus: 'AVAILABLE',
    operations: [{}],
    labElements: [],
    targetProduct: null
  },
  loading: false
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setElements(state, elements) {
    state.elements = elements;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  updateParameters({commit}, params) {
    commit('setParameters', params);
  },
  setLabElements({commit}, elements) {
    commit('setElements', elements);
  },
};

const getters = {
  parameters: state => {
    return state.parameters;
  },
  labElements: state => {
    return state.labElements;
  },
  isLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
