
const state = {
  dateTree: null,
};

const mutations = {
  setDateTree(state, tree) {
    state.dateTree = tree;
  },
};

const actions = {
  async setDateTree({commit}, tree) {
    commit('setDateTree', tree)
  },
};

const getters = {
  dateTree: state => {
    return state.dateTree;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
