<template>
  <v-navigation-drawer v-model="navState.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app class="d-print-none side-bar">
    <div class="d-flex d-lg-none justify-end mx-2 mt-2">
      <v-btn icon @click="hideDrawer"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <v-list dense>
      <template v-for="item in displayableItems">
        <v-row v-if="item.heading" :key="item.heading" align="center">
          <v-col cols="6">
            <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
          </v-col>
          <v-col cols="6" class="text-center">
            <a href="#!" class="body-2 black--text">EDIT</a>
          </v-col>
        </v-row>
        <v-list-group
          v-else-if="item.children"
          :key="item.text"
          v-model="item.active"
        >
          <template v-slot:activator>
            <v-list-item @click="clickLink(item)" link>
              <!-- <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action> -->
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-for="(child, i) in item.children" :key="i" @click="clickLink(child)" link>
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ child.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="item.text" @click="clickLink(item)" link>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div style="height: 80px"></div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser", "userRoles"]),
    ...mapGetters("navigation", ["navState"]),
    items(){
      return [
        { icon: "mdi-home", text: this.$vuetify.lang.t('$vuetify.sideBar.home'), link: "/" },
        { icon: "mdi-calendar-end-outline", text: this.$vuetify.lang.t('$vuetify.sideBar.settlement'), link: "/settlement" },
        { icon: "mdi-view-dashboard-outline", text: this.$vuetify.lang.t('$vuetify.sideBar.dashboard'), link:"/dashboard" },
        // { icon: "mdi-label", text: this.$vuetify.lang.t('$vuetify.sideBar.labelGen'), link:"/label-gen", meta: { authorize: ['Administrator', 'Manager'] }},
        // { icon: "mdi-label", text: this.$vuetify.lang.t('$vuetify.sideBar.label'), link:"/secure/labels", meta: { authorize: ['Administrator', 'Manager'] }},
        // { icon: "mdi-truck-delivery", text: this.$vuetify.lang.t('$vuetify.sideBar.receiving'), link:"/secure/purchase/receiving", meta: { authorize: ['Administrator', 'Manager'] } },
        { icon: "mdi-file-document-multiple-outline", text: this.$vuetify.lang.t('$vuetify.sideBar.worksheets'), link:"/processWorksheet" },
        { icon: "mdi-file-document-edit-outline", text: this.$vuetify.lang.t('$vuetify.sideBar.myWorksheet'), to: {name: 'MyWorksheets'} },
        { icon: "mdi-package-variant-closed", text: this.$vuetify.lang.t('$vuetify.sideBar.salesOrder'), link:"/open-order" },
        { icon: "mdi-flask-outline", text: this.$vuetify.lang.t('$vuetify.sideBar.laboratory'), link:"/secure/lab-result" },
        { icon: "mdi-calculator", text: this.$vuetify.lang.t('$vuetify.sideBar.inventorySelection'), link:"/inventory/lab-result/search" },
        { icon: "mdi-order-bool-ascending-variant", text: this.$vuetify.lang.t('$vuetify.sideBar.availableUnits'), link:"/secure/inventory/available-units" },
        { icon: "mdi-chart-timeline", text: this.$vuetify.lang.t('$vuetify.sideBar.process'), link:"/process-summary" },
        { icon: "mdi-radar", text: this.$vuetify.lang.t('$vuetify.sideBar.summary'), link:"/secure/inventory/trace" },
        { icon: "mdi-cog-outline", text: 'Settings', meta: { authorize: ['Administrator', 'Manager', 'Factory'] },
          children: [
            { icon: "mdi-domain", text: this.$vuetify.lang.t('$vuetify.sideBar.company'), link:"/company" },
            { icon: "mdi-truck", text: this.$vuetify.lang.t('$vuetify.sideBar.vehicle'), link:"/companyVehicle" },
            { icon: "mdi-map", text: this.$vuetify.lang.t('$vuetify.sideBar.manageMap'), link:"/maps/manage" },
            { icon: "mdi-qrcode", text: this.$vuetify.lang.t('$vuetify.sideBar.qrcode'), link:"/dynamicQrCode" },
            { icon: "mdi-cog-outline", text: this.$vuetify.lang.t('$vuetify.sideBar.worksheetType'), link:"/worksheetTypeDefinition" },
            { icon: "mdi-pin-outline", text: 'Reserved Inventory', link:"/inventoryReservation" },
            { icon: "mdi-account-hard-hat-outline", text: 'Supervisor', link:"/admin/supervisors" },
            { icon: "mdi-database-search-outline", text: 'Data Audit', link:"/audit" },
          ]
        },
        { icon: "mdi-tools", text: 'Tools', meta: { authorize: ['Administrator', 'Manager', 'Factory'] },
          children: [
            { icon: "mdi-file-pdf-box", text: 'PDF - Fill Forms', link:"/tools/pdf-fill-form" },
            { icon: "mdi-chart-gantt", text: 'Flow Control', link:"/flow-control" },
          ]
        }
      ];
    },
    displayableItems() {
      const allRoutes = this.$router.options.routes;
      const filterList = (items) => {
        return items.filter((item) => {
          let authorize = null;
          const route = allRoutes.find((r) => r.path == item.link || (item.to && r.name == item.to.name));
          if (route && route.meta ) {
            authorize = route.meta.authorize;
            return this.isLoggedIn && this.isAuthorized(authorize);
          } 
          else if(item.children){
            authorize = item.meta ? item.meta.authorize:[];
            return this.isLoggedIn && this.isAuthorized(authorize);
          }
          else if (!route) {
            console.warn("Uknown route found in Sidebar: " + item.link);
            return false;
          } else {
            return true; //include menu withtout authorize filter
          }
        });
      }

      const topLevels = filterList(this.items);
      if(topLevels){
        topLevels.forEach(top => {
          if(top && top.children && top.children.length > 0){
            top.children = filterList(top.children);
          }
        })
      }

      return topLevels;
    },
  },
  methods: {
    ...mapActions("navigation", ["hideDrawer"]),
    isAuthorized(roles) {
      if (!roles || roles.length < 1) {
        return true;
      }
      if (this.userRoles && this.userRoles.length > 0) {
        return this.userRoles.some((r) => roles.includes(r));
      } else {
        this.addErrorMessage("Unauthorized Access");
        return false;
      }
    },
    clickLink(item){
      if(item.link){
        this.hideDrawer();
        this.$router.push(item.link);
      }
      else if (item.to){
        this.hideDrawer();
        this.$router.push(item.to);
      }
    }
  },
};
</script>
