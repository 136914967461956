
    import {
      getOwnCompany,
      getAllCustomers,
      getAllSuppliers,
      createCompany,
      createAllCompanies,
      updateCompany,
      deleteCompany
    } from "@/services/company";

    const state = {
      own: null,
      customers: [],
      suppliers: [],
      processed: false
    };

    const getters = {
      own(state) {
        return state.own;
      },
      customers(state) {
        return state.customers;
      },
      suppliers(state) {
        return state.suppliers;
      },
      companyProcessed(state){
        return state.processed;
      }
    };

    const actions = {
        fetchOwn({ commit }) {
            commit("SET_PROCESSING");
          
            return new Promise((resolve, reject) => {
              getOwnCompany()
                .then(response => {
                  let own = null
                  if(response.data){
                    own = response.data;
                  }
                  commit("SET_OWN", own);
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error);
                });
            });
          },
        fetchCustomers({ commit }, params) {
            commit("SET_PROCESSING");
          
            const customerParams = Object.assign({page: 0, size: 10000}, params)

            return new Promise((resolve, reject) => {
              getAllCustomers(
                customerParams
              )
                .then(response => {
                  let customers = [];
                  if(response.data){
                    customers = response.data.content;
                  }
                  customers.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }));
                  commit("SET_CUSTOMERS", customers);
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error);
                });
            });
          },
        fetchSuppliers({ commit }, params) {
            commit("SET_PROCESSING");

            const supplierParams = Object.assign({page: 0, size: 10000}, params)

            return new Promise((resolve, reject) => {
              getAllSuppliers(
                supplierParams
              )
                .then(response => {
                  let suppliers = [];
                  if(response.data){
                    suppliers = response.data.content;
                  }
                  suppliers.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }));
                  commit("SET_SUPPLIERS", suppliers);
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error);
                });
            });
          },
        createCompany({ commit }, company) {
          return new Promise((resolve, reject) => {
            createCompany(company)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllCompanies({ commit }, companies) {
          return new Promise((resolve, reject) => {
            createAllCompanies(companies)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateCompany({ commit }, company) {
          return new Promise((resolve, reject) => {
            updateCompany(company)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteCompany({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteCompany(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_OWN(state, data) {
        state.own = data;
        state.processed = true;
      },
      SET_CUSTOMERS(state, data) {
        state.customers = data;
        state.processed = true;
      },
      SET_SUPPLIERS(state, data) {
        state.suppliers = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    