
    import Vue from "vue";

    const END_POINT = "/product-specs";

    const getProductSpecs = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllProductSpecs = data => Vue.axios.get(END_POINT, {params: data});

    const createProductSpecs = productSpecs => Vue.axios.post(END_POINT,  productSpecs );

    const createAllProductSpecs = ProductSpecs => Vue.axios.post(END_POINT + "/all",  ProductSpecs );

    const updateProductSpecs = productSpecs => Vue.axios.put(END_POINT  + "/" + productSpecs.id,  productSpecs );

    const deleteProductSpecs = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getProductSpecs,
      getAllProductSpecs,
      createProductSpecs,
      createAllProductSpecs,
      updateProductSpecs,
      deleteProductSpecs
    };
    