
  const worksheetTypeDefinition = {
    path: "/worksheetTypeDefinition",
    name: "worksheetTypeDefinition",
    component: () => import(/* webpackChunkName: "worksheet_type_definition" */ "@/components/worksheetTypeDefinition/WorksheetTypeDefinitionIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "worksheet_type_definition" */ "@/components/worksheetTypeDefinition/WorksheetTypeDefinitionView.vue"), name: "worksheetTypeDefinitionView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "worksheet_type_definition" */ "@/components/worksheetTypeDefinition/WorksheetTypeDefinitionEdit.vue"), name: "worksheetTypeDefinitionEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "worksheet_type_definition" */ "@/components/worksheetTypeDefinition/WorksheetTypeDefinitionCreate.vue"), name: "worksheetTypeDefinitionCreate"}
    ],
    meta: { authorize: ['Administrator', 'Manager'], requiresAuth: true }
  };

export default worksheetTypeDefinition;
    