
    import {
      getAllPageParameters,
      createPageParameters,
      createAllPageParameters,
      updatePageParameters,
      deletePageParameters
    } from "@/services/pageParameters";

    const state = {
      pageParameters: [],
      processed: false
    };

    const getters = {
      getPageParameters(state) {
        return state.pageParameters;
      },
      pageParametersProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchPageParameters({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllPageParameters(
              params
            )
              .then(response => {
                commit("SET_PAGEPARAMETERS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createPageParameters({ commit }, pageParameters) {
          return new Promise((resolve, reject) => {
            createPageParameters(pageParameters)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllPageParameters({ commit }, pageParameters) {
          return new Promise((resolve, reject) => {
            createAllPageParameters(pageParameters)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updatePageParameters({ commit }, pageParameters) {
          return new Promise((resolve, reject) => {
            updatePageParameters(pageParameters)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deletePageParameters({ commit }, id) {
          return new Promise((resolve, reject) => {
            deletePageParameters(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_PAGEPARAMETERS(state, data) {
        state.pageParameters = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    