
    import Vue from "vue";

    const END_POINT = "/processWorksheet";

    const getProcessWorksheet = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllProcessWorksheets = data => Vue.axios.get(END_POINT, {params: data});

    const getWorksheetUsers = () => Vue.axios.get(END_POINT + "/users");

    const getWorksheetSettlement = data => Vue.axios.get(END_POINT + "/settlement", {params: data});

    const createProcessWorksheet = processWorksheet => Vue.axios.post(END_POINT,  processWorksheet );

    const createAllProcessWorksheets = ProcessWorksheets => Vue.axios.post(END_POINT + "/all",  ProcessWorksheets );

    const updateProcessWorksheet = processWorksheet => Vue.axios.put(END_POINT  + "/" + processWorksheet.id,  processWorksheet );

    const submitProcessWorksheet = processWorksheet => Vue.axios.put(END_POINT  + "/submit/" + processWorksheet.id,  processWorksheet );

    const deleteProcessWorksheet = id => Vue.axios.delete(END_POINT  + "/" + id);

    const findInventory = (worksheetId, worksheetEntryId) => Vue.axios.get(END_POINT + "/find-inventory",  { params: { worksheetId, worksheetEntryId} } );


    export {
      getProcessWorksheet,
      getAllProcessWorksheets,
      getWorksheetUsers,
      getWorksheetSettlement,
      createProcessWorksheet,
      createAllProcessWorksheets,
      updateProcessWorksheet,
      submitProcessWorksheet,
      deleteProcessWorksheet,
      findInventory,
      
    };
    