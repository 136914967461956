import InventoryBalanceService from "@/services/InventoryBalanceService.js";
import AxiosObject from 'axios';
import moment from 'moment';

const state = {
  startBalanceService: {
    date: null,
    totalValue: 0,
    totalQuantity: 0,
    data: []
  },
  endBalanceService: {
    date: null,
    totalValue: 0,
    totalQuantity: 0,
    data: []
  },
  loading: false
};

const mutations = {
  setStartBalance(state, balance) {
    state.startBalanceService = balance;
  },
  setEndBalance(state, balance) {
    state.endBalanceService = balance;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  async loadInventoryBalance({ commit, state }, { startDate, endDate, includeCost, productIds = [] }) {
    const axios = this._vm.$axios;
    const requests = [];
    const start = moment(startDate);
    const end = moment(endDate);
    var duration = moment.duration(end.diff(start));
    var hours = duration.asHours();
    // const sameDay = startDate == endDate
    const sameDay = hours <= 24;
    if (state.startBalanceService.date) {
      console.log('destorying start balance data')
      state.startBalanceService.destroy();
    }
    if (state.endBalanceService.date) {
      console.log('destorying end balance data')
      state.endBalanceService.destroy();
    }

    if (startDate) {
      const formattedStartBalanceTime = start.format("YYYY-MM-DDTHH:mm:ss")
      const req1 = axios.get("inventory/balance", {
        params: {
          inventoryDate: formattedStartBalanceTime,
          includeCost,
          productIds: productIds.join(',')
        }
      })
      requests.push(req1);
    }

    if (endDate && startDate != endDate) {
      const formattedEndBalanceTime = end.format("YYYY-MM-DDTHH:mm:ss")
      const req2 = axios.get("inventory/balance", {
        params: {
          inventoryDate: formattedEndBalanceTime,
          includeCost,
          includeCost,
          productIds: productIds.join(',')
        }
      })
      requests.push(req2);
    }


    return await AxiosObject.all(requests)
      .then(AxiosObject.spread((...responses) => {
        let services = {}

        //Start Balance
        if (responses.length > 0) {
          const resp = responses[0];
          if (resp.data) {
            const data = resp.data.data;
            services.startBalanceService = new InventoryBalanceService(moment(startDate).toDate(), data);
            commit('setStartBalance', services.startBalanceService)

            if (sameDay) {
              commit('setEndBalance', services.startBalanceService)
            }
          }
        }

        //End balance
        if (responses.length > 1) {
          const resp = responses[1];
          if (resp.data) {
            const data = resp.data.data;
            services.endBalanceService = new InventoryBalanceService(moment(endDate).toDate(), data);
            commit('setEndBalance', services.endBalanceService)
          }
        }

        console.log('state after set start balance: ', state);

        return services;
      }))

  },

  //   async _loadFromServerBalance(date, axios) {
  // console.log('loading balance from server for date: ' + date)
  //     const params = { inventory_date: moment(date).format('YYYY-MM-DD') };

  //     return await axios
  //       .get("inventory/balance", { params })
  //       .then(resp => {

  //       });
  //   },

};

const getters = {
  startBalanceService: state => {
    return state.startBalanceService;
  },
  endBalanceService: state => {
    return state.endBalanceService;
  },
  isLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
