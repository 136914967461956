<template>
  <v-alert
    class="mt-2"
    :class="(showMessageBar ? '' : 'd-none') + '  message-bar'"
    :value="showMessageBar"
    transition="fade-transition"
    :type="messageClass"
    >{{ currentMessage }}</v-alert
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("messages", ["messages"]),
    showMessageBar() {
      return this.messages.message && this.messages.message.length > 0;
    },
    messageClass() {
      const options = ["info", "success", "warning", "error", "danger"];
      const n = options.includes(this.messages.messageClass);
      if (n) {
        return this.messages.messageClass === "danger"
          ? "error"
          : this.messages.messageClass;
      } else {
        return "info";
      }
    },
    currentMessage() {
      return this.messages.message;
    }
  },
  created() {
    this.clearMessage();
  },
  methods: {
    ...mapActions("messages", ["clearMessage"]),
    closeMessageBar() {
      this.clearMessage();
    }
  }
};
</script>

<style scoped>
.message-bar {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  min-height: 50px;
  z-index: 9999;
  margin-left: -25%;
}
</style>
