
    import {
      getAllLabElements,
      createLabElement,
      createAllLabElements,
      updateLabElement,
      deleteLabElement
    } from "@/services/labElement";

    const state = {
      labElements: [],
      processed: false
    };

    const getters = {
      getLabElements(state) {
        return state.labElements;
      },
      labElementProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchLabElements({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllLabElements(
              params
            )
              .then(response => {
                commit("SET_LABELEMENTS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createLabElement({ commit }, labElement) {
          return new Promise((resolve, reject) => {
            createLabElement(labElement)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllLabElements({ commit }, labElements) {
          return new Promise((resolve, reject) => {
            createAllLabElements(labElements)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateLabElement({ commit }, labElement) {
          return new Promise((resolve, reject) => {
            updateLabElement(labElement)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteLabElement({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteLabElement(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_LABELEMENTS(state, data) {
        state.labElements = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    