
    import {
      getAllProductSpecsElements,
      createProductSpecsElement,
      createAllProductSpecsElements,
      updateProductSpecsElement,
      deleteProductSpecsElement
    } from "@/services/productSpecsElement";

    const state = {
      productSpecsElements: [],
      processed: false
    };

    const getters = {
      getProductSpecsElements(state) {
        return state.productSpecsElements;
      },
      productSpecsElementProcessed(state){
        return state.processed;
      }
    };

    const actions = {
      fetchProductSpecsElements({ commit }, params) {
          commit("SET_PROCESSING");
          return new Promise((resolve, reject) => {
            getAllProductSpecsElements(
              params
            )
              .then(response => {
                commit("SET_PRODUCTSPECSELEMENTS", response.data);
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createProductSpecsElement({ commit }, productSpecsElement) {
          return new Promise((resolve, reject) => {
            createProductSpecsElement(productSpecsElement)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        createAllProductSpecsElements({ commit }, productSpecsElements) {
          return new Promise((resolve, reject) => {
            createAllProductSpecsElements(productSpecsElements)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        updateProductSpecsElement({ commit }, productSpecsElement) {
          return new Promise((resolve, reject) => {
            updateProductSpecsElement(productSpecsElement)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        },
        deleteProductSpecsElement({ commit }, id) {
          return new Promise((resolve, reject) => {
            deleteProductSpecsElement(id)
              .then(response => {
                commit("SET_PROCESSED");
                resolve(response.data);
              })
              .catch(error => {
                reject(error);
              });
          });
        }
      };

    const mutations = {
      SET_PRODUCTSPECSELEMENTS(state, data) {
        state.productSpecsElements = data;
        state.processed = true;
      },
      SET_PROCESSING(state) {
        state.processed = false;
      },
      SET_PROCESSED(state) {
        state.processed = true;
      }
    };

    export default {
      state,
      getters,
      actions,
      mutations
    };
    