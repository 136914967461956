
  const qrCode = {
    path: "/qr-code",
    name: "QrCode",
    component: () => import(/* webpackChunkName: "qr_code" */ "@/components/qrCode/QRHome.vue"),
    children: [
      { path: "worksheet/:uuid/", component: () => import(/* webpackChunkName: "qr-code" */ "@/components/qrCode/WorksheetQRCodePrint.vue"), name: "worksheetQRCodePrint" },
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default qrCode;
    