import TreeModel from "tree-model";

const state = {
  openAllState: 'closed',
  products: [],
  loading: false
};

const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  setOpenAllState(state, openState) {
    state.openAllState = openState;
  },
  setLoading(state, status) {
    state.loading = status;
  }
};

const actions = {
  async loadProducts({commit}) {

    return await this._vm.$axios
      .get("category/childs", {
        params: {
          code: "PRODUCTS"
        }
      })
      .then(response => {
        var data = response.data;
        if (data && data.code != 200) {
          let message = data.message;
          this._vm.$store.dispatch("messages/addMessage", {
            message,
            messageClass: "danger"
          });
          return;
        } else {
          const rootProducts = data.data;
          const root = { name: 'root', label: 'root', id: 0, children: rootProducts }

          const model = new TreeModel();
          const rootCategory = model.parse(root);
          //assign level
          rootCategory.walk( (node) => { 
            const path = node.getPath();
            const hasChildren = node.hasChildren();
            node.model.level = path.length - 1; //exclude root
            node.model.visible = node.model.level == 1 ? true:false;
            node.model.path = path.map(p => p.model.id);
            node.model.hasChildren = hasChildren;
            if(hasChildren) {
              const leafNodes = node.all(function (n) {
                  return !n.hasChildren();
              });
              if(leafNodes && leafNodes.length > 0)
                node.model.leafNodeIds = leafNodes.map( l => l.model.id);
            }
            else{
              delete node.model.children;
            }
            node.model.state = 'closed';
          } )

          const products = [];
          const productNodes = rootCategory.all();
          productNodes.forEach(n => {
            if(n.model.id > 0){//skip root
              const p = n.model;
              p.label = p.name;
              products.push(p);
            }
          })

          commit("setProducts", products);

          return products;
        }
      });
  },
  // getLeafNodes(rootNode){
  //   if(rootNode){
  //     var leafNodes = rootNode.all(function (node) {
  //         return !node.model.hasChildren;
  //     });
  //     return leafNodes;
  //   }
  //   else{
  //     return [];
  //   }
  // },
  getParentNode({state}, node){
    const productNode = state.products.find(p => {
      if(p.hasChildren && p.children && p.children.length > 0){
        const children = p.children;
        const matchIndex = children.findIndex( c => c.id == node.id);
        return matchIndex > -1;
      }
      else{
        return false;
      }
    });

    return productNode;
  },
  toggleProductGroup({state}, product){
    const productNode = state.products.find(p => p.id == product.id);
    if(productNode.state == 'open'){
      productNode.state = 'closed'
    }
    else{ productNode.state = 'open' }
    //Set all descendents visibility to hidden
    if(productNode.state == 'closed'){
      state.products.forEach(p => {
        if(p.id != product.id && p.path.includes(product.id)){
          p.visible = false
        }
      })
    }
    else{ 
      productNode.children.forEach(child => {
        const childNode = state.products.find(p => p.id == child.id);
        childNode.visible = true
        if(childNode.hasChildren){
          childNode.state = 'closed'
        }
      })
    }

  },
  openCloseAllProducts({commit, state}, openOrClose){
    if(state.openAllState == 'open' || openOrClose == 'closed'){
      commit('setOpenAllState', 'closed');
      state.products.forEach((product => {
        product.state = 'closed';
        if(product.level > 1){
          product.visible = false
        }
      } ));
    }
    else{       
      commit('setOpenAllState', 'open');
      state.products.forEach((product => {
        product.state = 'open';
        product.visible = true
      }));
    }

  },
  closeAllProducts({commit, state}){
    commit('setOpenAllState', 'closed');
    state.products.forEach((product => {
      product.state = 'closed';
      if(product.level > 1){
        product.visible = false
      }
      else if(product.level == 1){
        product.visible = true;
      }
    } ));
  },
  openAllProducts({commit, state}){
    commit('setOpenAllState', 'open');
    state.products.forEach((product => {
      product.state = 'open';
      product.visible = true
    }));
  },
  showHideProductGroups({state}, show){
    if(show){
      state.products.forEach(p => {
        if(p.hasChildren){
          p.visible = true;
        }
      })
    }
    else{
      state.products.forEach(p => {
        if(p.hasChildren){
          p.visible = false;
        }
      })
    }
  },
  showProductIds({state}, {productIds, showGroup}){
    const parentIds = new Set();
    state.products.forEach( p => {
        //prepare parent groups
        if(productIds.includes(p.id)){
          p.visible = true;
          if(!p.hasChildren && showGroup){
            p.path.forEach( p => parentIds.add(p))
          }
        }
        else{
          p.visible = false
        }
    })

    state.products.forEach( p => {
      if(parentIds.has(p.id)){
        p.visible = true;
        p.state = 'open'
      }
    })
  }
};

const getters = {
  products: state => {
    return state.products;
  },
  openAllState: state => {
    return state.openAllState;
  },
  isAppLoading: state => {
    return state.loading;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
