import {getCookie, eraseCookie} from "@/store/util/cookieUtils";
import Vue from "vue";

const state = {
	status: '',
	token: '',
	user: null,
}

const mutations = {
	auth_request(state) {
		state.status = 'loading'
	},
	auth_success(state, payload) {
		state.status = 'success'
		state.token = payload.token
		state.user = payload.user
	},
	auth_error(state) {
		state.status = 'error'
	},
	auth_logout(state) {
		state.status = ''
		state.token = ''
		state.user = null
	},
}

const actions = {
	
	updateUserToken({ commit }, obj) {
		const vm = obj.vm;
		const token = obj.auth.token;
		const user = obj.auth.user;

		if(process.env.NODE_ENV == 'development'){
			localStorage.setItem("token", token);
			vm.$axios.defaults.headers.common["Authorization" ] = `Bearer ${token}`;
		}

		commit({
			type: 'auth_success',
			token: token,
			user: user
		});
	},
	processLogout( {commit} ) {
		const cookieName = 'bml-token';

		//Check if JWT Token is already in cookie, if not, manually set (could be cross domain)
		const jwt = getCookie(cookieName);
		if(jwt){
			eraseCookie(cookieName)
		}

		//remove token from localstorage
		// localStorage.removeItem('token')

		commit('auth_logout')
	},
	async remoteJwtTokenCheck( {commit} ){
		// console.log('initiating jwt token check')
		return await Vue.axios.get(process.env.VUE_APP_AUTHHOST +'/jwt-token-check')
			.then( (resp ) => {
				const token = resp.data.jwttoken;
				const user = resp.data.user;
				
				commit({
					type: 'auth_success',
					token: token,
					user: user
				});
			} )
			.catch( () => {
				throw 'Fail JWT token validation';
			})
	}
}

const getters = {
	isLoggedIn: state => state.user != null,
	authStatus: state => state.status,
	currentUser: (state) => {
		return state.user
	},
	userRoles: (state) => {
		if(state.user){
			return state.user.roles;
		}
		else{
			return []
		}
	},
	isAdministrator: (state) => {
		return Array.isArray(state.user.roles) &&  state.user.roles.indexOf('ADMIN') > -1;
	},
	
}

export default {
	state,
	mutations,
	actions,
	getters
}
