
const inventoryLabResult = {
  path: "/secure/lab-result",
  name: "inventoryLabResult",
  component: () => import(/* webpackChunkName: "inventoryLabResultView" */ `@/components/inventoryLabResult/InventoryLabResultIndex.vue`),
  children: [
    { path: "view/:id", component: () => import(/* webpackChunkName: "inventoryLabResultView" */ `@/components/inventoryLabResult/InventoryLabResultView.vue`) , name: "inventoryLabResultView" },
    { path: "edit/:id", component: () => import(/* webpackChunkName: "inventoryLabResultView" */ `@/components/inventoryLabResult/InventoryLabResultEdit.vue`), name: "inventoryLabResultEdit" },
    { path: "create", component: () => import(/* webpackChunkName: "inventoryLabResultView" */ `@/components/inventoryLabResult/InventoryLabResultCreate.vue`) , name: "inventoryLabResultCreate"},
    { path: "upload", component: () => import(/* webpackChunkName: "inventoryLabResultView" */ `@/components/inventoryLabResult/InventoryLabResultUpload.vue`) , name: "inventoryLabResultUpload"},
    { path: "coaCheck", component: () => import(/* webpackChunkName: "inventoryLabResultView" */ `@/components/inventoryLabResult/InventoryCoaCheck.vue`) , name: "inventoryCoaCheck"}
  ],
  meta: { authorize: ['Administrator', 'Manager', 'Factory', 'Technician'], requiresAuth: true }
};

export default inventoryLabResult;
    