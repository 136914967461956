
    import Vue from "vue";

    const END_POINT = "/worksheetTypeDefinition";

    const getWorksheetTypeDefinition = id => Vue.axios.get(END_POINT + "/" + id);

    const getAllWorksheetTypeDefinitions = data => Vue.axios.get(END_POINT, {params: data});

    const createWorksheetTypeDefinition = worksheetTypeDefinition => Vue.axios.post(END_POINT,  worksheetTypeDefinition );

    const createAllWorksheetTypeDefinitions = WorksheetTypeDefinitions => Vue.axios.post(END_POINT + "/all",  WorksheetTypeDefinitions );

    const updateWorksheetTypeDefinition = worksheetTypeDefinition => Vue.axios.put(END_POINT  + "/" + worksheetTypeDefinition.id,  worksheetTypeDefinition );

    const deleteWorksheetTypeDefinition = id => Vue.axios.delete(END_POINT  + "/" + id);

    export {
      getWorksheetTypeDefinition,
      getAllWorksheetTypeDefinitions,
      createWorksheetTypeDefinition,
      createAllWorksheetTypeDefinitions,
      updateWorksheetTypeDefinition,
      deleteWorksheetTypeDefinition
    };
    