
  const pageParameters = {
    path: "/pageParameters",
    name: "pageParameters",
    component: () => import(/* webpackChunkName: "page_parameters" */ "@/components/pageParameters/PageParametersIndex.vue"),
    children: [
      { path: "view/:id", component: () => import(/* webpackChunkName: "page_parameters" */ "@/components/pageParameters/PageParametersView.vue"), name: "pageParametersView" },
      { path: "edit/:id", component: () => import(/* webpackChunkName: "page_parameters" */ "@/components/pageParameters/PageParametersEdit.vue"), name: "pageParametersEdit" },
      { path: "create", component: () => import(/* webpackChunkName: "page_parameters" */ "@/components/pageParameters/PageParametersCreate.vue"), name: "pageParametersCreate"}
    ],
    meta: { authorize: [], requiresAuth: true }
  };

export default pageParameters;
    